import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select, Typography,
  withStyles
} from '@material-ui/core'
import CloudDownload from '@material-ui/icons/CloudDownload'
import LocalOfferOutlined from '@material-ui/icons/LocalOfferOutlined'
import React, { Component, Fragment } from 'react'
import FeatureBar from '../layout/FeatureBar'
import MainContent from '../layout/MainContent'
import Spacer from '../layout/Spacer'
import Dates from '../utils/date'
import Formatter from '../utils/Formatter'
import hours from '../utils/hours'
import { ProductPriceItem } from './ProductPrice'
import RecoverScheduleService from './RecoverScheduleService'

class RecoverScheduleProducts extends Component {
  state = {
    products: undefined,
    downloading: false,
    startTime: '',
    endTime: '',
    dialog: false
  }

  componentDidMount = () => {
    this.fetchProductsForSchedule()
  }

  fetchProductsForSchedule = () => {
    RecoverScheduleService.recover(this.props.match.params.recover).then(
      products => {
        products = products.map(p => {
          p.suggestions = p.suggestions.sort(this.compareStores)
          return p
        })
        this.setState({ products })
      }
    )
  }

  handleDisableAll = product => () => {
    const { products } = this.state

    const pIndex = products.findIndex(p => p._id === product._id)
    let editProduct = products[pIndex]

    editProduct.suggestions.map(s => {
      s.removed = true
      return s
    })

    products[pIndex] = editProduct
    RecoverScheduleService.updateProduct(product)
    this.setState({ products })
  }

  downloadProducts = () => {
    const { startTime, endTime } = this.state

    if (startTime == '' || endTime == '') {
      return
    }

    this.setState({ downloading: true, dialog: false })
    RecoverScheduleService.download(
      this.props.match.params.recover,
      startTime,
      endTime
    )
      .then(data => {
        this.setState({ downloading: false })
        const name = data.name.replace(' ', '-').toLowerCase()
        const products = data.products

        const element = document.createElement('a')
        const file = new Blob(products, { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = `${name}.txt`
        element.click()
      })
      .catch(() => this.setState({ downloading: false }))
  }

  compareStores(a, b) {
    const bandA = a.store.toUpperCase()
    const bandB = b.store.toUpperCase()

    let comparison = 0
    if (bandA > bandB) {
      comparison = 1
    } else if (bandA < bandB) {
      comparison = -1
    }
    return comparison
  }

  handleClose = () => {
    this.setState({ dialog: false })
  }

  render = () => {
    let { products, downloading, dialog, startTime, endTime } = this.state
    const { classes } = this.props

    let total = 0

    if (products) {
      let suggestions = []
      products.forEach(p => p.suggestions.forEach(s => suggestions.push(s)))

      total = total = suggestions
        .filter(p => !p.removed)
        .reduce(
          (a, b) => a + (b.suggestedPrice - b.price) * b.estimatedTotalSales,
          0
        )
    }

    const dates = (products && products[0]) || {}

    return (
      <Fragment>
        <Dialog onClose={this.handleClose} open={dialog}>
          <DialogTitle id="simple-dialog-title">
            Selecione os horários de início e fim
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {Dates.format(dates.start || '')}
                </Typography>
                <Spacer />
                <FormControl variant="outlined" style={{ minWidth: 220 }}>
                  <InputLabel htmlFor="start">Início</InputLabel>
                  <Select
                    label="Início"
                    onChange={e => this.setState({ startTime: e.target.value })}
                    id="start"
                    fullWidth
                    variant="outlined"
                    value={startTime}
                  >
                    {hours.map(h => (
                      <MenuItem key={h} value={h}>
                        {h}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {Dates.format(dates.end || '')}
                </Typography>
                <Spacer />
                <FormControl variant="outlined" style={{ minWidth: 220 }}>
                  <InputLabel htmlFor="end">Encerramento</InputLabel>
                  <Select
                    label="Encerramento"
                    onChange={e => this.setState({ endTime: e.target.value })}
                    id="end"
                    fullWidth
                    variant="outlined"
                    value={endTime}
                  >
                    {hours.map(h => (
                      <MenuItem key={h} value={h}>
                        {h}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.downloadProducts} color="primary">
              Baixar Arquivo
            </Button>
          </DialogActions>
        </Dialog>
        <Card
          style={{
            position: 'fixed',
            top: 90,
            left: '70%',
            padding: 16,
            zIndex: 100
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.accent}
                align="center"
                variant="h5"
              >
                {Formatter.currency(total)}
              </Typography>
            </Grid>
            <Grid justify="center" container item xs={12}>
              <Typography align="center" variant="caption">
                Previsão de Recuperação
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Container maxWidth="lg">
          <FeatureBar title={'Produtos da agenda'} />
          <MainContent>
            <Grid item xs={12}>
              {downloading ? (
                <div style={{ height: 52 }}>
                  <LinearProgress color="secondary" />
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  size="large"
                  onClick={() => this.setState({ dialog: true })}
                  style={{
                    marginBottom: 16,
                    alignSelf: 'center'
                  }}
                >
                  <CloudDownload
                    style={{
                      marginRight: 8,
                      fontSize: 20,
                      color: '#ffffff'
                    }}
                  />
                  Baixar Arquivo
                </Button>
              )}
            </Grid>
            <Card
              style={{ position: 'sticky', position: '-webkit-sticky' }}
              style={{ padding: 16 }}
              variant="outlined"
              color="secondary"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    className={classes.accent}
                    align="center"
                    variant="h3"
                  >
                    {Formatter.currency(total)}
                  </Typography>
                </Grid>
                <Grid justify="center" container item xs={12}>
                  <Typography align="center" variant="body1">
                    Previsão de Recuperação
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Spacer double />
            {products === undefined ? (
              <Paper>
                <Grid>
                  <Typography>Aguarde, carregando produtos...</Typography>
                </Grid>
              </Paper>
            ) : (
              <List>
                {products &&
                  products.map((p, pIndex) => {
                    let hasSuggestions = false
                    p.suggestions.forEach(s =>
                      !s.removed ? (hasSuggestions = true) : null
                    )
                    return (
                      <Fragment>
                        <ListItem
                          style={{
                            padding: 20,
                            height: 76,
                            background: 'white',
                            marginTop: 24
                          }}
                        >
                          <Grid container justify="space-between">
                            <Grid container item xs={10}>
                              <Grid item>
                                <LocalOfferOutlined
                                  style={{ marginRight: 16, marginTop: 4 }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">
                                 {p.code} {p.description} - {p.brand}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid style={{ alignSelf: 'flex-end' }} item xs={2}>
                              {hasSuggestions && (
                                <Button
                                  tabIndex={-1}
                                  onClick={this.handleDisableAll(p)}
                                >
                                  Remover Produto
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider />
                        <Grid>
                          {p.suggestions.map((s, i) => (
                            <ProductPriceItem
                              key={i}
                              pIndex={pIndex}
                              onUpdate={product => {
                                products[pIndex] = { ...product }
                                this.setState({ products: [...products] })
                              }}
                              product={p}
                              index={i}
                            />
                          ))}
                        </Grid>
                      </Fragment>
                    )
                  })}
              </List>
            )}
          </MainContent>
        </Container>
      </Fragment>
    )
  }
}
const styles = theme => ({
  accent: {
    color: theme.palette.secondary.main
  }
})

export default withStyles(styles)(RecoverScheduleProducts)
