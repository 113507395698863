import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const LabeledData = ({ classes, label, data }) =>
  <div className={classes.container}>
    <div className={classes.label}>
      {label}
    </div>
    <div className={classes.data}>
      {data}
    </div>
  </div>

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(4),
  },
  label: {
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#5b7d99',
  },
  data: {
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    color: '#4c687f',
  },
})

export default withStyles(styles)(LabeledData)