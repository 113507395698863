import { Component } from 'react'
import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { RecoverService } from './RecoverService'
import { withStyles } from '@material-ui/core/styles'
import APIService from '../http/ApiService'
import FeatureBar from '../layout/FeatureBar'
import Loading from '../common/Loading'
import MainContent from '../layout/MainContent'
import NoResults from '../common/NoResults'
import React, { Fragment } from 'react'

class RecoverProductsContainer extends Component {

  state = {
    fetching: true,
    products: undefined,
    details: undefined,
    total: 0,
  }

  componentDidMount = () => {
    this.fetchData()
    this.fetchSaleDetails()
  }

  fetchSaleDetails = () => {
    RecoverService.suggestionDetails(this.props.match.params.sale)
      .then(details => this.setState({ details }))
  }

  handlePriceChanged = key => {
    APIService.post(`/recover/suggestions/products/${key}`)
      .then(res => this.fetchData())
      .catch(e => console.log(e))
  }

  fetchData = () =>
    RecoverService.products(this.props.match.params.sale)
      .then(products => this.setState({ fetching: false, products }))
      .catch(e => console.log(e))


  formatDate = date => {
    const year = date.substr(0, 4)
    const month = date.substr(5, 2)
    const day = date.substr(8, 2)
    return `${day}/${month}/${year}`
  }

  render = () => {
    const product = this.state.products && this.state.products[0]
    return (
      <Fragment>
        <FeatureBar title={this.state.details && this.state.details.description} />
        <MainContent>
          <Grid item xs={12}>
            {this.state.fetching ? <Loading /> :
              this.state.products && this.state.products.length > 0 ?
                <Paper elevation={1}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Descricao</TableCell>
                        <TableCell>Preco</TableCell>
                        <TableCell>Dt. Início</TableCell>
                        <TableCell>Dt. Fim</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.products.map((product, index) =>
                        <TableRow key={product.code + product.barcode} className={this.props.classes.row}>
                          <TableCell>{product.code}</TableCell>
                          <TableCell>{product.description}<br /><span>{product.complements}</span></TableCell>
                          <TableCell>{product.suggestedPriceFormatted}</TableCell>
                          <TableCell>{this.formatDate(product.startDate)}</TableCell>
                          <TableCell>{this.formatDate(product.endDate)}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Paper> : <NoResults message="Nenhuma alteração de preços temporários" />}
          </Grid>
        </MainContent>
      </Fragment>
    )
  }
}

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  checked: {
    color: '#43A047',
  },
  notChecked: {
    opacity: 0.4,
  },
  saveButton: {
    marginBottom: 24,
  }
})

export default withStyles(styles)(RecoverProductsContainer)