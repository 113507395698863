import React, { Component, Fragment } from 'react'

import { Divider } from '@material-ui/core'
import LabeledData from '../common/LabeledData'
import Loading from '../common/Loading'
import MainContent from '../layout/MainContent'
import Spacer from '../layout/Spacer'
import { StockoutService } from './StockoutService'
import { Styles } from '../Styles'
import Title from '../common/Title'
import { withStyles } from '@material-ui/core/styles'

class StockoutProductDetails extends Component {

  state = {
    product: undefined,
    fetching: true,
  }

  componentDidMount = () => {
    StockoutService.product(this.props.product)
      .then(response => response.data)
      .then(product => this.setState({ product, fetching: false }))
      .catch(e => console.log(e))
  }

  render = () => {
    const { classes } = this.props
    const { product } = this.state
    return (
      <MainContent>
        {this.state.fetching ? <Loading /> : null}
        {product ?
          <Fragment>
            <Title title={product.description} caption={(product.complement ? `${product.complement} -` : '' ) + ' ' + product.brand} />
            <div style={Styles.label}>{}</div>
            <div className={classes.container}>
              <LabeledData label="Curva ABC" data={product.abc} />
              <LabeledData label="Departamento" data={product.departmentDescription} />
              <LabeledData label="Pedido" data={product.ordered ? 'Sim' : 'Não'} />
              <LabeledData label="Data do Pedido" data={product.dateOrderedFormatted || '-'} />
              <LabeledData label="Lojas" data={product.stores.join(', ')} />
            </div>
            <Spacer double />
            <Divider />
            <Spacer double />
            {product.orders && Object.keys(product.orders) ?
              Object.keys(product.orders).map(key => {
                const order = product.orders[key]
                return (
                  <Fragment key={key}>
                    <Title title="Pedidos" />
                    <div className={classes.container}>
                      <LabeledData label="Data" data={order.date} />
                      <LabeledData label="Fornecedor" data={order.supplier} />
                    </div>
                    <div className={classes.container}>
                      <LabeledData label="Loja" data={key} />
                      <LabeledData label="Quantidade" data={order.amount} />
                    </div>
                  </Fragment>
                )
              }) : null}

          </Fragment>
          : null}
      </MainContent>
    )
  }
}

const styles = theme => ({
  title: {
    marginLeft: 24,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: 24,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
})

export default withStyles(styles)(StockoutProductDetails)