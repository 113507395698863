export const ToggleDrawer = '[Drawer] Toggle Drawer'

const initialState = {
  drawerOpen: false
}

const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ToggleDrawer: {
      return { ...state, drawerOpen: !state.drawerOpen }
    }
    default:
      return state
  }
}

export default drawerReducer

export const getDrawerStatus = state => state.drawer.drawerOpen
