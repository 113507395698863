import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core"
import React, { Component, Fragment } from "react"

import { ClassificationService } from "./ClassificationService"
import FeatureBar from "../layout/FeatureBar"
import MainContent from "../layout/MainContent"

class ClassificationContainer extends Component {
  state = {
    groups: [],
    products: [],
    availableProducts: [],
    groupSelected: {},
    allSelected: false,
    count: 0
  }

  componentDidMount = () => {
    this.fetchGroups()
    this.fetchProducts()
    this.fetchCount()
  }

  fetchGroups = () => {
    ClassificationService.groups().then(groups => this.setState({ groups }))
  }

  fetchProducts = () => {
    ClassificationService.products().then(products =>
      this.setState({ products }, this.fillAvailableProducts)
    )
  }

  fetchCount = () => {
    ClassificationService.count()
      .then(response => this.setState({ count: response.count }))
      .catch()
  }

  handleGroupSelection = e => {
    const value = e.target.value
    const groupArray = this.state.groups.filter(g => g.grupo_codigo === value)
    const group = groupArray[0]

    if (!group) return

    this.setState({ groupSelected: group })
  }

  handleProductSelected = (idx, state) => {
    const products = this.state.availableProducts.slice()
    const product = products[idx]

    if (state) {
      product.isSelected = false
    } else {
      product.isSelected = true
    }

    products[idx] = product

    this.setState({
      availableProducts: products
    })
  }

  handleAllSelection = () => {
    let products = this.state.availableProducts.slice()
    products = products.map(this.invertSelection(this.state.allSelected))
    this.setState(state => ({
      availableProducts: products,
      allSelected: !state.allSelected
    }))
  }

  invertSelection = allSelected => p => {
    if (allSelected) {
      delete p.isSelected
    } else {
      p.isSelected = true
    }
    return p
  }

  fillAvailableProducts = () => {
    const availableProducts = this.state.availableProducts
    let numAvailableProducts = this.state.availableProducts.length

    let hasElements = true

    while ((numAvailableProducts < 10) & hasElements) {
      const product = this.state.products.shift()
      if (product) {
        availableProducts.push(product)
        numAvailableProducts = numAvailableProducts + 1
      } else {
        hasElements = false
      }
    }

    this.setState({
      availableProducts
    })
  }

  removedSelectedProducts = () => {
    let availableProducts = [...this.state.availableProducts]
    availableProducts = availableProducts.filter(p => !p.isSelected)
    this.setState(
      {
        availableProducts
      },
      () => this.fillAvailableProducts()
    )
  }

  saveProduct = () => {
    if (!this.state.groupSelected || !this.state.groupSelected.grupo_codigo) {
      return alert("Selecione um grupo")
    }

    if (this.state.groupSelected && this.state.groupSelected.grupo_codigo) {
      let selectedProducts = this.state.availableProducts.filter(
        p => p.isSelected
      )
      selectedProducts = selectedProducts.map(p => {
        p.department = this.state.groupSelected.departamento
        p.group = this.state.groupSelected.grupo_codigo
        return p
      })

      this.setState({ isSaving: true }, () => {
        ClassificationService.saveProducts(selectedProducts)
          .then(r => {
            this.removedSelectedProducts()
            this.fetchCount()
            this.setState({ isSaving: false, allSelected: false })
          })
          .catch(e => alert(e.message))
      })
    }
  }

  render = () => (
    <Fragment>
      <FeatureBar title="Classificar Produtos" />
      <MainContent>
        <Grid>
          <Typography style={{ marginBottom: 16, color: 'blue' }} variant="display1">
            {this.state.count} produtos
          </Typography>
        </Grid>
        <Grid spacing={8} container wrap="wrap">
          <Grid item xs={4}>
            <Typography style={{ marginBottom: 16 }} variant="subtitle2">
              Grupos
            </Typography>
            <Card>
              <CardContent>
                <Grid item>
                  <select
                    style={{
                      padding: 8,
                      lineHeight: 20,
                      fontSize: 16,
                      width: "100%"
                    }}
                    onClick={this.handleGroupSelection}
                    size={20}
                  >
                    {this.state.groups.map(g => (
                      <option
                        value={g.grupo_codigo}
                        style={{ fontSize: 12, padding: 4 }}
                        key={g.grupo_codigo}
                      >
                        {g.classificacao} - {g.descricao} - {g.grupo_codigo}
                      </option>
                    ))}
                  </select>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ marginBottom: 16 }} variant="subtitle2">
              Classificar como: {this.state.groupSelected.descricao}
            </Typography>
            <Button
              style={{ marginBottom: 16, marginLeft: 0 }}
              onClick={this.saveProduct}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
            <Card>
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          aria-checked={this.state.allSelected}
                          checked={this.state.allSelected}
                          onChange={this.handleAllSelection}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          Selecionar todos
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.availableProducts.map((p, i) => (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={this.state.allSelected}
                        tabIndex={-1}
                        selected={p.isSelected}
                        key={p.barcode}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            aria-checked={!!p.isSelected}
                            checked={!!p.isSelected}
                            onChange={() =>
                              this.handleProductSelected(i, p.isSelected)
                            }
                          />
                        </TableCell>
                        <TableCell
                          onClick={() =>
                            this.handleProductSelected(i, p.isSelected)
                          }
                        >
                          <Typography variant="body1">
                            {p.description} {p.complement}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MainContent>
    </Fragment>
  )
}

export default ClassificationContainer
