import { Button, Icon, TableCell, TableRow } from '@material-ui/core'

import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const StockoutItem = ({ product, classes, onCheck, onRemove }) => {
  return (
    <TableRow className={classes.row}>
      <TableCell style={{ opacity: product.ordered ? 0.3 : 1 }}>{product.code}</TableCell>
      <TableCell>
        <div className={product.abc === 'A' ? classes.urgent : product.abc === 'B' ? classes.important : ''}>
        </div>
      </TableCell>
      <TableCell style={{ opacity: product.ordered ? 0.3 : 1 }}>{product.description} {product.complement} - {product.brand}</TableCell>
      <TableCell style={{ opacity: product.ordered ? 0.3 : 1 }}>{product.inventory}</TableCell>
      <TableCell style={{ opacity: product.ordered ? 0.3 : 1 }}>{product.stores.join(', ')}</TableCell>
      <TableCell style={{ opacity: product.ordered ? 0.3 : 1 }}>{product.lastPurchase}</TableCell>
      <TableCell>
        <Tooltip id="button-check" title="Pedido">
          <Button style={{ opacity: 1 }} onClick={() => onCheck(product._key)}>
            <Icon className={product.ordered ? classes.checked : classes.notChecked}>check</Icon>
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  urgent: {
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: '#f44336'
  },
  important: {
    width: 16,
    height: 16,
    borderRadius: 50,
    backgroundColor: '#ff9800'
  },
  checked: {
    color: '#43A047'
  },
  notChecked: {
    opacity: 0.4
  }
})

export default withStyles(styles)(StockoutItem)