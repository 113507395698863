import { AUTHENTICATE_SUCCESS, LOGOUT } from './AuthActions'

const initialState = {
  isLoggedIn: false,
  token: undefined,
  user: undefined
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        token: action.payload.token
      }
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        token: undefined,
        user: undefined,
      }
    }
    default:
      return state
  }
}

export const getUser = state => state.auth.user

export default reducer