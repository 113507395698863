import { STOCKOUT_PRODUCTS_FETCH_SUCCESS } from './StockoutActions'

const initialState = {
  products: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCKOUT_PRODUCTS_FETCH_SUCCESS: {
      return { ...state, products: action.payload }
    }

    default: {
      return state
    }
  }
}

export default reducer

export const getStockoutProducts = state => state.stockout.products