import Api from '../http/ApiService'

export class UserService {
  static users = () => Api.get(`/users`).then(response => response.data)
  static user = key =>
    Api.get(`/users/details/${key}`).then(response => response.data)
  static sectors = () => Api.get('/sectors').then(response => response.data)
  static update = user => Api.put(`/users/${user._key}`, user)
  static create = user => Api.post(`/users`, user)
  static reset = key => Api.post(`/users/${key}/reset`)
  static activate = key => Api.post(`/users/${key}/activate`)
  static deactivate = key => Api.post(`/users/${key}/deactivate`)
  static userInfo = key =>
    Api.get(`/users/${key}`).then(response => response.data)
  static permissions = () =>
    Api.get('/users/permissions').then(response => response.data)
}

export default UserService
