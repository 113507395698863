import { put, call, takeLatest } from 'redux-saga/effects'
import {
  GOALS_MONTH_FETCH,
  GOALS_DAILY_FETCH,
  GOALS_MONTH_FETCH_SUCCESS,
  GOALS_DAILY_FETCH_SUCCESS,
} from './GoalsActions'
import { GoalsService } from './GoalsService'

function* fetchGoalsMonth(action) {
  try {
    const response = yield call(GoalsService.month, action.payload)

    if (response.data) {
      yield put({ type: GOALS_MONTH_FETCH_SUCCESS, payload: response.data })
    }
  } catch (e) {

  }
}

function* fetchGoalsDaily(action) {
  try {
    const response = yield call(GoalsService.daily, action.payload)

    if (response.data) {
      yield put({ type: GOALS_DAILY_FETCH_SUCCESS, payload: response.data })
    }
  } catch (e) {

  }
}

export default [
  takeLatest(GOALS_MONTH_FETCH, fetchGoalsMonth),
  takeLatest(GOALS_DAILY_FETCH, fetchGoalsDaily),
]