import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from '../common/Loading';
import FeatureBar from '../layout/FeatureBar';
import MainContent from '../layout/MainContent';
import { getActiveStore } from '../store/StoreReducer';
import { fetchStockoutProducts, saveStockoutProduct } from './StockoutActions';
import StockoutItem from './StockoutItem';
import { getStockoutProducts } from './StockoutReducer';
import { StockoutService } from './StockoutService';


class StockoutProductsContainer extends Component {

  state = {
    products: undefined,
    fetching: false,
  }

  componentDidMount = () =>
    this.setState({ fetching: true }, () =>
      StockoutService.productsForDepartment(this.props.match.params.dep)
        .then(response => response.data)
        .then(products => this.setState({ fetching: false, products }))
        .catch(e => console.log(e)))

  handleCheck = key => {
    let product
    const products = this.state.products.map(p => {
      if (p._key === key) {
        product = p
        p.ordered = !p.ordered
      }
      return p
    })

    this.props.saveStockoutProduct(product)
    this.setState(state => ({ products, }))
  }

  handleRemove = key => {
    let index = this.state.products.findIndex((p, i) => p._key === key)
    let product = this.state.products[index]
    product.removed = true

    const products = [...this.state.products]
    products.splice(index, 1)

    this.props.saveStockoutProduct(product)
    this.setState(state => ({ products }))
  }

  render = () => {
    return (
      <Fragment>
        <FeatureBar history={this.props.history} title={`Ruptura em ${this.props.match.params.description}`} />
        <MainContent>
          {this.state.fetching ? <Loading /> : null}
          {this.state.products ?
            <Grid item xs={12}>
              <Paper elevation={1}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Importância</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Estoque</TableCell>
                      <TableCell>Ruptura</TableCell>
                      <TableCell>Última compra</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.products && this.state.products.map((product, index) =>
                      <StockoutItem
                        onCheck={this.handleCheck}
                        onRemove={this.handleRemove}
                        key={product._key}
                        index={index}
                        product={product} />
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </Grid> : null}
        </MainContent>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  activeStore: getActiveStore(state),
  data: getStockoutProducts(state),
})

const mapDispatchToProps = dispatch => ({
  fetchProducts: bindActionCreators(fetchStockoutProducts, dispatch),
  saveStockoutProduct: bindActionCreators(saveStockoutProduct, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StockoutProductsContainer)