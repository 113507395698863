import { IconButton, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import React, { PureComponent } from 'react'

class PriceChangeItem extends PureComponent {
  render = () => {
    const { id, classes, codes, description, complements, brand, stores, suggestedPriceFormatted, onCheck } = this.props
    return (
      <TableRow className={classes.row}>
        <TableCell>{codes && codes[0]}</TableCell>
        <TableCell>
          {description}
          <br />
          <span style={{ opacity: 0.57 }}>{complements}</span>
        </TableCell>
        <TableCell>{brand}</TableCell>
        <TableCell>{stores ? stores.join(', ') : 'Todas'}</TableCell>
        <TableCell>{suggestedPriceFormatted}</TableCell>
        <TableCell>
          <IconButton onClick={() => onCheck(id)}>
            <CheckIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
}

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  checked: {
    color: '#43A047',
  },
  notChecked: {
    opacity: 0.4,
  },
})

export default withStyles(styles)(PriceChangeItem)