import Api from '../http/ApiService'

export class PriceService {
  static changes = () =>
    Api.get('/price/changes')
      .then(response => response.data)

  static change = key =>
    Api.get(`/price/changes/${key}`)
      .then(response => response.data)

  static markAllDone = key =>
      Api.get(`/price/changes/mark-all-done`)
}

export default PriceService