import React from 'react'
import {
  Assignment,
  AssignmentReturned,
  AssignmentTurnedIn,
  LocalOffer,
  MonetizationOn,
  People,
  ShoppingCart,
  SpeakerNotes
  } from '@material-ui/icons'

const menuItems = [
  {
    permission: 'StockoutScreen',
    route: '/ruptura',
    icon: <ShoppingCart />,
    label: 'Ruptura'
  },
  {
    permission: 'PriceChangesScreen',
    route: '/precos',
    icon: <LocalOffer />,
    label: 'Gerenciar Preços'
  },
  {
    permission: 'RecoverMarginProducts',
    route: '/precos-temporarios',
    icon: <MonetizationOn />,
    label: 'Preços Temporários'
  },
  {
    permission: 'RecoverMargin',
    route: '/recover-margin',
    icon: <MonetizationOn />,
    label: 'Recuperação de Margem'
  },
  {
    permission: 'RecoverSchedule',
    route: '/recover-schedule',
    icon: <MonetizationOn />,
    label: 'Agenda de Recuperação'
  },
  {
    permission: 'ViewGoalsScreen',
    route: '/metas',
    icon: <AssignmentTurnedIn />,
    label: 'Metas'
  },
  {
    permission: 'ViewGoalsScreen',
    route: '/metas-departamentos',
    icon: <AssignmentTurnedIn />,
    label: 'Metas - Departamentos'
  },
  {
    permission: 'ViewGoalsScreen',
    route: '/metas-importar',
    icon: <AssignmentTurnedIn />,
    label: 'Metas - Importar'
  },
  {
    permission: 'ManageRequests',
    route: '/requisicoes',
    icon: <AssignmentReturned />,
    label: 'Requisições'
  },
  {
    permission: 'ManageQuotes',
    route: '/cotacao',
    icon: <SpeakerNotes />,
    label: 'Cotação'
  },
  {
    permission: 'CreateTasks',
    route: '/tasks',
    icon: <Assignment />,
    label: 'Tarefas'
  },
  {
    permission: 'ManageUsers',
    route: '/usuarios',
    icon: <People />,
    label: 'Usuários'
  }
]

export default menuItems
