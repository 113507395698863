import Api from '../http/ApiService'

export class GoalsService {
  static daily = store =>
    Api.get(`/goals/stores/${store}/daily`).then(response => response.data)

  static month = store =>
    Api.get(`/goals/stores/${store}/month`).then(response => response.data)

  static goal = key => Api.get(`/goals/${key}`).then(response => response.data)

  static save = goal =>
    Api.post(`/goals/${goal._key}`, goal).then(response => response.data)

  static recalculateMonth = key =>
    Api.get(`/goals/recalculate-month/${key}`).then(response => response.data)

  static departmentGoals = store =>
    Api.get(`/goals/departments/stores/${store}/month`).then(
      response => response.data
    )

  static updateDepartmentGoal = (store, goal) =>
    Api.post(`/goals/departments/stores/${store}/month`, goal).then(
      response => response.data
    )

  static import = goals =>
    Api.post('/goals/import', goals).then(response => response.data)
}

export default GoalsService
