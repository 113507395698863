import { CardContent, Grid, TextField, Typography } from '@material-ui/core'
import React, { Component, Fragment } from 'react'

import Button from '@material-ui/core/Button'
import { GoalsService } from './GoalsService'
import Loading from '../common/Loading'
import MainContent from '../layout/MainContent'
import NumberFormat from 'react-number-format'
import SaveIcon from '@material-ui/icons/Save'
import Spacer from '../layout/Spacer'
import { withStyles } from '@material-ui/core/styles'

class GoalsEditContainer extends Component {

  state = {
    goal: undefined,
    fetching: true,
    revenue: 0,
    margin: 0,
    profit: 0,
    purchases: 0,
    averageTicket: 0
  }

  componentDidMount = () =>
    this.fetchGoal()

  updateState = goal => {
    const newState = Object.assign({}, goal, { fetching: false })
    newState.margin = goal.margin * 100
    this.setState({ ...newState, goal })
  }

  fetchGoal = () =>
    GoalsService.goal(this.props.goal)
      .then(goal => this.updateState(goal))
      .catch(e => this.setState({ fetching: false }))

  handleChange = field => e => {
    this.setState({ [field]: e.floatValue || 0.1 })
  }

  save = () => {
    const originalGoal = this.state.goal
    originalGoal.revenue = this.state.revenue
    originalGoal.profit = this.state.profit
    originalGoal.margin = this.state.margin / 100
    originalGoal.purchases = this.state.purchases
    originalGoal.averageTicket = this.state.averageTicket

    GoalsService.save(originalGoal)
      .then(response => this.props.onClose())
      .catch(e => console.log(e))
  }

  render = () => {
    return (
      <Fragment>
        <MainContent>
          <CardContent>
            <Typography variant="subtitle2">Meta Diária</Typography>
            <Spacer size={16} />
            {this.state.fetching ? <Loading /> :
              <Grid item xs={12}>
                <NumberFormat
                  id="revenue"
                  label="Faturamento R$"
                  margin="normal"
                  className={this.props.classes.input}
                  customInput={TextField}
                  value={this.state.revenue}
                  onValueChange={this.handleChange('revenue')}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={'R$ '} />
                <NumberFormat
                  id="profit"
                  label="Profit R$"
                  margin="normal"
                  className={this.props.classes.input}
                  customInput={TextField}
                  value={this.state.profit}
                  onValueChange={this.handleChange('profit')}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={'R$ '}
                />
                <NumberFormat
                  id="purchases"
                  label="Compras R$"
                  margin="normal"
                  className={this.props.classes.input}
                  customInput={TextField}
                  value={this.state.purchases}
                  onValueChange={this.handleChange('purchases')}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={'R$ '}
                />
                <NumberFormat
                  id="averageTicket"
                  label="Ticket Médio R$"
                  margin="normal"
                  className={this.props.classes.input}
                  customInput={TextField}
                  value={this.state.averageTicket}
                  onValueChange={this.handleChange('averageTicket')}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix={'R$ '}
                />
                <Spacer size={16} />
                <Button onClick={this.save} variant="contained" color="secondary" className={this.props.classes.button}>
                  <SaveIcon className={this.props.classes.leftIcon} />
                  Save
                </Button>
                <Button onClick={() => this.props.onClose()} className={this.props.classes.button}>
                  Cancelar
                </Button>
              </Grid>}
          </CardContent>
        </MainContent>
      </Fragment>
    )
  }
}

const styles = theme => ({
  input: {
    marginRight: 24,
  },
  saveButton: {
    marginTop: 24,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
})

export default withStyles(styles)(GoalsEditContainer)