import Api from '../http/ApiService'

export class StockoutService {
  static stats = () => Api.get('/stockout/departments')

  static products = () => Api.get('/stockout/products')

  static product = key => Api.get(`/stockout/products/${key}`)

  static saveProduct = product =>
    Api.get(`/stockout/products/${product._key}/${product.ordered}`)

  static updateProduct = (product, update) =>
    Api.post(`/stockout/products/${product._key}`, update)

  static productsForDepartment = (dep, stores) =>
    Api.get(
      stores && stores.length > 0
        ? `/stockout/departments/${dep}/products/stores/${stores.join(';')}`
        : `/stockout/departments/${dep}/products`
    )

  static productsCSV = () =>
    Api.get(`/stockout/download-csv`).then(response => response.data)

  static quotationDownload = () =>
    Api.get(`/stockout/quotation/download`).then(response => response.data)

  static departments = stores =>
    Api.get(
      stores && stores.length > 0
        ? `/stockout/departments/stores/${stores.join(';')}`
        : `/stockout/departments`
    ).then(response => response.data)
}

export default StockoutService
