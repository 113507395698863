import EditIcon from '@material-ui/icons/EditOutlined'
import Formatter from '../utils/Formatter'
import React from 'react'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'
import SaveIcon from '@material-ui/icons/SaveOutlined'
import UndoIcon from '@material-ui/icons/UndoOutlined'
import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography
  } from '@material-ui/core'

export default function PriceEdit({
  suggestion,
  index,
  onEnable,
  onDisable,
  onEdit,
  onCommit,
  onKeyPress,
  onPriceChange
}) {
  return (
    <TableRow style={{ opacity: suggestion.removed ? 0.4 : 1 }}>
      <TableCell>
        <Typography variant="subtitle2">{suggestion.store}</Typography>
      </TableCell>
      <TableCell>{suggestion.priceFormatted}</TableCell>
      <TableCell>
        <TextField
          autoFocus={true}
          onKeyPress={onKeyPress(index)}
          onFocus={event => event.target.select()}
          onChange={onPriceChange(index)}
          style={{ maxWidth: 60 }}
          value={suggestion.suggestedPrice}
        />
        {/* {suggestion.editing ? (
        ) : (
          Formatter.currency(
            suggestion.suggestedPrice.toString().replace('.', ','),
            2
          )
        )} */}
      </TableCell>
      <TableCell align="right">
        {suggestion.editing ? (
          <IconButton color="primary" onClick={() => onCommit(index)}>
            <SaveIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => onEdit(index)}>
            <EditIcon color="primary" />
          </IconButton>
        )}
        {suggestion.removed ? (
          <IconButton onClick={() => onEnable(index)}>
            <UndoIcon color="primary" />
          </IconButton>
        ) : (
          <IconButton onClick={() => onDisable(index)}>
            <RemoveIcon color="error" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}
