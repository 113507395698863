import { Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@material-ui/core'
import { PriceService } from './PriceService'
import APIService from '../http/ApiService'
import FeatureBar from '../layout/FeatureBar'
import Loading from '../common/Loading'
import MainContent from '../layout/MainContent'
import NoResults from '../common/NoResults'
import PriceChangeItem from './PriceChangeItem'
import React, { Component, Fragment } from 'react'

class PriceContainer extends Component {
  state = {
    changes: undefined,
    fetching: false,
    fetched: false,
  }

  componentDidMount = () => this.fetchData()

  handlePriceChangeDone = key => {
    APIService.post(`/price/changes/${key}`)
      .then(() => this.fetchData())
      .catch(e => console.log(e))
  }

  fetchData = () => {
    this.setState({ fetching: true }, () =>
      PriceService.changes().then(changes => this.setState({ fetching: false, changes }))
    )
  }

  handleCompleteAll = () => {
    const completeAll = window.confirm('Deseja marcar todas as alterações como concluídas?')

    if (completeAll) {
      this.setState({ fetching: true }, () =>
        PriceService.markAllDone()
          .then(() => this.setState({ changes: undefined, fetching: false }))
          .catch(e => console.log(e))
      )
    }
  }

  render = () => {
    return (
      <Fragment>
        <FeatureBar title='Novos Preços' />
        <MainContent>
          <Grid item xs={12}>
            {this.state.fetching ? (
              <Loading />
            ) : this.state.changes && this.state.changes.length > 0 ? (
              <Fragment>
                <Button
                  onClick={this.handleCompleteAll}
                  variant='raised'
                  color='primary'
                  style={{
                    alignSelf: 'right',
                    marginBottom: 24,
                  }}
                >
                  Concluir todas
                </Button>
                <Paper elevation={1}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Marca</TableCell>
                        <TableCell>Lojas</TableCell>
                        <TableCell>Preço</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.changes.map((change, index) => (
                        <PriceChangeItem
                          key={change._key}
                          id={change._key}
                          codes={change.codes}
                          description={change.description}
                          complements={change.complements}
                          brand={change.brand}
                          stores={change.stores}
                          suggestedPriceFormatted={change.suggestedPriceFormatted}
                          onCheck={this.handlePriceChangeDone}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Fragment>
            ) : (
              <NoResults message='Nenhuma alteração de preços pendente' />
            )}
          </Grid>
        </MainContent>
      </Fragment>
    )
  }
}

export default PriceContainer