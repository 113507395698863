import { Card, CircularProgress, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import FeatureBar from '../layout/FeatureBar'
import MainContent from '../layout/MainContent'
import RecoverScheduleService from './RecoverScheduleService'

export const RecoverReportContainer = props => {
  const { schedule } = props.match.params

  const [products, setProducts] = useState()

  const fetchScheduleReport = async () => {
    try {
      const products = await RecoverScheduleService.report(schedule)
      setProducts(products)
    } catch (error) {
      console.log(error)
    }
  }

  const calculatePriceChange = suggestion => {
    suggestion.suggestedPriceFormatted = suggestion.suggestedPriceFormatted?.replaceAll('R$ ', '')
    const suggestedPrice = +((suggestion.suggestedPriceFormatted || '0').replaceAll(',', '.'))
    const price = suggestion.price || 0
    return Math.abs(price - suggestedPrice)
  }
  const calculatePercentageChange = suggestion => {
    const change = calculatePriceChange(suggestion)
    return ((change / suggestion.price) * 100).toFixed(1)
  }


  useEffect(() => {
    if (schedule) {
      fetchScheduleReport()
    }
  }, [])

  console.log(products)

  return (
    <Container maxWidth="lg">
      <FeatureBar title="Recuperacao Margem" />
      <MainContent>
        <Grid container>{!products && <CircularProgress />}</Grid>
        {products &&
          <Grid container direction='column' spacing={2}>
            {products.map(p =>
              <div style={{ marginBottom: 24 }}>
                <Grid item xs={12}>
                  <Card style={{ padding: 8 }}>
                    <Typography variant='h6' >{p.code} - {p.description}</Typography>
                  </Card>
                </Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Loja
                      </TableCell>
                      <TableCell>
                        Preço
                      </TableCell>
                      <TableCell>
                        Quantidade
                      </TableCell>
                      <TableCell>
                        Lucro
                      </TableCell>
                      <TableCell>
                        Faturamento
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {p.suggestions.map(s =>
                      <TableRow>
                        <TableCell>
                          {s.store}
                        </TableCell>
                        <TableCell>
                          {s.suggestedPriceFormatted} - {s.priceFormatted} = {calculatePriceChange(s).toFixed(2).replaceAll(',', '.')} ({calculatePercentageChange(s)}%)
                        </TableCell>
                        <TableCell>
                          {s.totalAmount.toFixed(2)}
                        </TableCell>
                        <TableCell>
                          R$ {s.totalProfit.toFixed(2)}
                        </TableCell>
                        <TableCell>
                          R$ {s.totalRevenue.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            )}
          </Grid>
        }
      </MainContent>
    </Container>
  )
}
