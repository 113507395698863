import {
  Grid,
  Button,
  Input,
  Checkbox,
  FormControlLabel,
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Card,
  CardContent,
  Box,
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Divider,
  ListItemSecondaryAction
} from '@material-ui/core'
import React, { Component, Fragment } from 'react'
import TaskService from './TaskService'
import MainContent from '../layout/MainContent'
import FeatureBar from '../layout/FeatureBar'
import Spacer from '../layout/Spacer'
import SaveIcon from '@material-ui/icons/Save'
import {
  Add,
  Check,
  CheckCircle,
  CheckCircleOutlineOutlined,
  RemoveOutlined,
  DeleteOutlineOutlined
} from '@material-ui/icons'

const daysOfMonth = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31
]

const daysOfWeek = [
  {
    label: 'Segunda',
    value: 1
  },
  {
    label: 'Terça',
    value: 2
  },
  {
    label: 'Quarta',
    value: 3
  },
  {
    label: 'Quinta',
    value: 4
  },
  {
    label: 'Sexta',
    value: 5
  },
  {
    label: 'Sábado',
    value: 6
  },
  {
    label: 'Domingo',
    value: 7
  }
]

class CreateTaskContainer extends Component {
  state = {
    id: undefined,
    key: undefined,
    users: [],
    selectedUsers: [],
    selectedDays: [],
    subtasks: [],
    subtaskTitle: '',
    title: '',
    when: 'weekdays'
  }

  componentDidMount = () => {
    TaskService.users()
      .then(users => this.setState({ users }))
      .then(() => {
        const key = this.props.match.params.key
        if (key) {
          TaskService.task(key).then(task => {
            task.subtasks = task.subtasks || []
            task.users = task.users || []

            if (task) {
              this.setState({
                id: task._id,
                key: task._key,
                selectedUsers: task.users,
                selectedDays: task.days,
                subtasks: task.subtasks,
                subtaskTitle: '',
                title: task.title,
                when: task.when
              })
            }
          })
        }
      })
  }

  addSubtask = () => {
    if (!this.state.subtaskTitle) {
      return
    }

    const subtasks = [...this.state.subtasks]
    subtasks.push({
      status: 'Ativa',
      title: this.state.subtaskTitle
    })
    this.setState({ subtasks, subtaskTitle: '' })
  }

  onRemoveSubtask = title => {
    const { subtasks } = this.state
    const index = subtasks.findIndex(s => s.title === title)
    subtasks.splice(index, 1)
    this.setState({ subtasks })
  }

  save = () => {
    const {
      id,
      key,
      title,
      subtasks,
      when,
      selectedDays,
      selectedUsers
    } = this.state

    if (
      title === '' ||
      when === '' ||
      selectedDays.length === 0 ||
      selectedUsers.length === 0
    ) {
      return alert('Preencha todos os campos')
    }

    const task = {}
    task._id = id
    task._key = key
    task.users = selectedUsers
    task.days = selectedDays
    task.title = title
    task.subtasks = subtasks
    task.when = when

    if (task._id) {
      TaskService.update(task)
        .then(() => alert('Tarefa salva'))
        .catch(e => alert(e.message))
    } else {
      TaskService.create(task)
        .then(() => {
          this.setState({
            selectedDays: [],
            selectedUsers: [],
            subtasks: [],
            subtaskTitle: '',
            title: ''
          })
          alert('Tarefa criada com sucesso')
        })
        .catch(e => alert(e.message))
    }
  }

  handleUserSelection = user => () => {
    const selectedUsers = this.state.selectedUsers
    const index = selectedUsers.indexOf(user)
    if (index > -1) {
      selectedUsers.splice(index, 1)
    } else {
      selectedUsers.push(user)
    }
    this.setState({ selectedUsers })
  }

  handleDaySelection = day => () => {
    const selectedDays = this.state.selectedDays
    const index = selectedDays.indexOf(day)
    if (index > -1) {
      selectedDays.splice(index, 1)
    } else {
      selectedDays.push(day)
    }
    this.setState({ selectedDays })
  }

  render = () => {
    const {
      users,
      selectedUsers,
      selectedDays,
      subtasks,
      title,
      subtaskTitle,
      when
    } = this.state
    return (
      <Fragment>
        <FeatureBar title='Editar Tarefa' />
        <MainContent>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  fullWidth={true}
                  value={title}
                  onChange={event =>
                    this.setState({ title: event.target.value })
                  }
                  title='Título da Ttarefa'
                  placeholder='Digite o título da tarefa'
                />
              </Grid>
            </Grid>
            <Box padding={3} />
            <Divider />
            <Box padding={3} />
            <Typography variant='h6'>Subtarefas</Typography>
            <Box padding={2} />
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Card elevation={0}>
                  <CardContent>
                    <TextField
                      variant='outlined'
                      value={subtaskTitle}
                      fullWidth
                      onChange={event =>
                        this.setState({ subtaskTitle: event.target.value })
                      }
                      title='Subtarefa'
                      placeholder='Adicione uma subtarefa'
                    />
                    <Box padding={1} />
                    <Button
                      onClick={this.addSubtask}
                      color='primary'
                      disableElevation
                      variant='text'
                    >
                      Adicionar Subtarefa
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                {Boolean(subtasks) && subtasks.length === 0 ? (
                  <Box padding={4}>
                    <Typography align='center' variant='body1'>
                      Cadastre subtarefas com o formulário ao lado
                    </Typography>
                  </Box>
                ) : (
                  <List>
                    {subtasks.map((t, index) => (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <CheckCircleOutlineOutlined />
                        </ListItemAvatar>
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => this.onRemoveSubtask(t.title)}
                          >
                            <DeleteOutlineOutlined />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <ListItemText primary={t.title} />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>
            <Box padding={4} />
            <Divider />
            <Box padding={4} />
            <Typography variant='h6'>Usuários</Typography>
            <Spacer size={8} />
            <Grid container>
              {users.map(u => (
                <Grid key={u._id} item xs={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedUsers.indexOf(u._id) > -1}
                        onChange={this.handleUserSelection(u._id)}
                        value={u.name}
                      />
                    }
                    label={u.email}
                  />
                </Grid>
              ))}
            </Grid>
            <Spacer size={36} />
            <Typography variant='h6'>Período</Typography>
            <Spacer size={24} />
            <Grid container>
              <FormControl>
                <Select
                  value={when}
                  onChange={event => {
                    this.setState({
                      when: event.target.value,
                      selectedDays: []
                    })
                  }}
                >
                  <MenuItem value={'weekdays'}>Dias da Semana</MenuItem>
                  <MenuItem value={'days'}>Dias do mês</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Spacer size={24} />
            <Grid container>
              {when === 'weekdays'
                ? daysOfWeek.map(d => (
                    <Grid key={d.value} item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.indexOf(d.value) > -1}
                            onChange={this.handleDaySelection(d.value)}
                            value={d.value.toString()}
                          />
                        }
                        label={d.label}
                      />
                    </Grid>
                  ))
                : daysOfMonth.map(d => (
                    <Grid key={d} item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedDays.indexOf(d) > -1}
                            onChange={this.handleDaySelection(d)}
                            value={d.toString()}
                          />
                        }
                        label={d.toString()}
                      />
                    </Grid>
                  ))}
            </Grid>
            <Spacer size={32} />
            <Button onClick={this.save} variant='contained' color='secondary'>
              <SaveIcon />
              <Spacer />
              Adicionar Tarefa
            </Button>
          </Grid>
        </MainContent>
      </Fragment>
    )
  }
}

export default CreateTaskContainer
