import { AUTHENTICATE_ERROR } from "../auth/AuthActions"
import { store } from "../Store"

class HttpService {
  get(url) {
    return this.request(url, "GET")
  }

  post(url, payload) {
    return this.request(url, "POST", payload)
  }

  put(url, payload) {
    return this.request(url, "PUT", payload)
  }

  delete(url) {
    return this.request(url, "DELETE")
  }

  checkAuthError = response => {
    if (response.status === 401)
      return store.dispatch({ type: AUTHENTICATE_ERROR })
    return response
  }

  insertTokenIntoHeaders = headers => {
    const token = localStorage.getItem("token")

    headers.append("Content-Type", "application/json")

    if (token) {
      headers.append("Authorization", token)
    }
  }

  fetchWithTimeout = (url, params, timeout) =>
    new Promise((resolve, reject) => {
      const errorTimeout = setTimeout(
        reject.bind(null, new Error("Timeout")),
        timeout
      )
      fetch(`${url}`, params)
        .then(res => {
          clearTimeout(errorTimeout)
          resolve(res)
        })
        .catch(e => reject(e))
    })

  request = async (url, method, payload) => {
    const headers = new Headers()
    this.insertTokenIntoHeaders(headers)

    const params = {
      method,
      headers,
      body: JSON.stringify(payload)
    }

    try {
      const response = await this.fetchWithTimeout(`${url}`, params, 45000)
      this.checkAuthError(response)
      const jsonResponse = await response.json()

      return jsonResponse
    } catch (e) {
      throw e
    }
  }
}

export default new HttpService()
