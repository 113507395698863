import { put, call, takeLatest } from 'redux-saga/effects'
import { PRICE_CHANGES_FETCH_SUCCESS, PRICE_CHANGES_FETCH } from './PriceActions'
import { PriceService } from './PriceService'

function* fetchPriceChanges() {
  try {
    const response = yield call(PriceService.changes)
    if (response.data) {
      yield put({ type: PRICE_CHANGES_FETCH_SUCCESS, payload: response.data })
    }
  } catch (e) {
    console.log(e)
  }
}

export default [
  takeLatest(PRICE_CHANGES_FETCH, fetchPriceChanges),
]