export const defaultPermissions = {
  CategoryGoalsScreen: false,
  ChangeStore: false,
  ChangeToConsolidated: false,
  CreateTasks: false,
  ManageQuotes: false,
  ManageRequests: false,
  ManageUsers: false,
  NetworkScreen: false,
  OnlineOverviewScreen: false,
  OnlineSectorGoalsScreen: false,
  OnlineSectorsScreen: false,
  PriceChangesScreen: false,
  PriceManagementScreen: false,
  ProductSearchScreen: false,
  RecoverMarginProducts: false,
  RecoverOfferScreen: false,
  SetGoalsScreen: false,
  StockoutDepartmentsScreen: false,
  StockoutScreen: false,
  SupervisionInventoryStatsScreen: false,
  TaskListScreen: false,
  TimelineScreen: false,
  ViewConsultingHistory: false,
  ViewGoalsScreen: false,
  Reimport: false,
  Expiration: false
}

export const userPermissions = [
  {
    name: 'Visualizar outras lojas',
    description:
      'Permite o usuário trocar de loja e acessar dados de outras lojas',
    value: 'ChangeStore',
    activate: [],
    deactivate: []
  },
  {
    name: 'Visualizar dados consolidados',
    description: 'Visualizar as informações de todas as lojas consolidadas',
    value: 'ChangeToConsolidated',
    activate: [],
    deactivate: []
  },
  {
    name: 'Visualizar vendas e lucro em valores monetários',
    description:
      'Acesso completo aos valores de vendas, podendo visualizar venda, metas, lucro e histórico.',
    value: 'OnlineOverviewScreen',
    activate: [],
    deactivate: ['CategoryGoalsScreen']
  },
  {
    name: 'Visualizar vendas dos departamentos e categorias',
    description:
      'Acesso completo aos valores de vendas, dos departamentos e categorias.',
    value: 'OnlineSectorsScreen',
    activate: [],
    deactivate: ['OnlineSectorGoalsScreen']
  },
  {
    name:
      'Ver resultado das metas de departamentos e categorias com valores percentuais',
    description:
      'Visualizar metas de venda das categorias com somente valores percentuais, ideal para cargos com acesso restrito a valores de vendas.',
    value: 'CategoryGoalsScreen',
    activate: [],
    deactivate: ['OnlineOverviewScreen']
  },
  {
    name: 'Vendas dos setores com valores percentuais',
    description:
      'Visualizar dados da venda dos setores com somente valores percentuais, ideal para cargos com acesso restrito a valores de vendas.',
    value: 'OnlineSectorGoalsScreen',
    activate: [],
    deactivate: ['OnlineSectorsScreen']
  },
  {
    name: 'Gerenciar Preços',
    description:
      'Permite visualizar, aceitar ou remover sugestões de gerenciamento de preços',
    value: 'PriceManagementScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Visualizar alterações de preços',
    description:
      'Permite visualizar as alterações de preços através da interface web no endereço https://app.atlasbi.com.br',
    value: 'PriceChangesScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Pesquisar produtos',
    description: 'Permite pesquisar produtos pelo nome ou código de barras.',
    value: 'ProductSearchScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Visualizar preços temporários',
    description:
      'Visualizar alterações de preços da recuperação de margem na interface web no endereço https://app.atlasbi.com.br',
    value: 'RecoverMarginProducts',
    activate: [],
    deactivate: []
  },
  {
    name: 'Recuperação de margem',
    description: 'Permite criar recuperações de oferta',
    value: 'RecoverOfferScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Definir metas',
    description: 'Permite alterar os valores das metas da loja',
    value: 'SetGoalsScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Ruptura',
    description: 'Permite visualizar produtos em ruptura no aplicativo',
    value: 'StockoutDepartmentsScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Ruptura Web',
    description:
      'Permite visualizar ruptura de produtos na interface web no endereço https://app.atlasbi.com.br',
    value: 'StockoutScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Tarefas',
    description: 'Permite visualizar tarefas',
    value: 'TaskListScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Cartões (Mapa)',
    description: 'Permite visualizar os cartões do mapa',
    value: 'TimelineScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Visualizar metas',
    description:
      'Permite somente visualizar as metas da loja em que o usuário está autorizado',
    value: 'ViewGoalsScreen',
    activate: [],
    deactivate: []
  },
  {
    name: 'Criar e gerenciar requisições',
    description: 'Permite criar, gerenciar e adicionar produtos à requisições',
    value: 'ManageRequests',
    activate: [],
    deactivate: []
  },
  {
    name: 'Criar e gerenciar cotações',
    description: 'Permite criar uma cotação e adicionar produtos',
    value: 'ManageQuotes',
    activate: [],
    deactivate: []
  },
  {
    name: 'Gerenciar Usuários',
    description: 'Permite adicionar, editar e remover usuários',
    value: 'ManageUsers',
    activate: [],
    deactivate: []
  },
  {
    name: 'Criar rotinas de tarefas',
    description:
      'Permite criar rotinas de tarefas na interface web no endereço https://app.atlasbi.com.br',
    value: 'CreateTasks',
    activate: [],
    deactivate: []
  },
  {
    name: 'Validade',
    description:
      'Permite visualizar e marcar conferência de produtos que estão com validade próxima',
    value: 'Expiration',
    activate: [],
    deactivate: []
  }
]
