import Api from "../http/ApiService"

export class RequestService {
  static requests = store =>
    Api.get(`/request/exported/${store}`).then(response => response.data)

  static products = store =>
    Api.get(`/request/products/${store}`).then(response => response.data)

  static download = key =>
    Api.get(`/request/download/${key}`).then(response => response.data)

  static createRequest = store =>
    Api.post(`/request/create/${store}`).then(response => response.data)
}

export default RequestService
