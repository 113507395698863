import { appSpacing, defaultFontSize, lightFont } from '../Styles'

import React from 'react'
import { TextSecondary } from '../Colors'
import { withStyles } from '@material-ui/core/styles'

const Title = ({ classes, title, caption }) =>
  <div className={classes.container}>
    <div className={classes.title}>
      {title}
    </div>
    {caption ? 
    <div className={classes.caption}>
      {caption}
    </div> : null }
  </div>

const styles = theme => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    flex: 1,
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    color: '#3e5569',
  },
  caption: {
    color: TextSecondary,
    fontFamily: lightFont,
    fontSize: defaultFontSize,
    marginTop: appSpacing,
  }
})

export default withStyles(styles)(Title)