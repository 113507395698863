import MenuList from './menu'
import React, { Component } from 'react'
import TypoBody from '../typography/body'
import TypoCaption from '../typography/caption'
import { connect } from 'react-redux'
import {
  Divider,
  Drawer,
  Grid,
  List
  } from '@material-ui/core'
import { DrawerColor } from '../Colors'
import { getDrawerStatus, ToggleDrawer } from './reducer'
import { store } from '../Store'
import { withStyles } from '@material-ui/core/styles'

class AppDrawer extends Component {
  render = () => {
    const { user, history, classes, drawerStatus } = this.props
    return (
      <Drawer
        variant="temporary"
        open={drawerStatus}
        className={classes.drawer}
        onClick={() => store.dispatch({ type: ToggleDrawer })}
        classes={{ paper: classes.drawerPaper }}
      >
        <Grid className={classes.container}>
          <TypoBody label={user && user.name} inverse />
          <TypoCaption label={user && user.email} inverse />
        </Grid>
        <Divider variant="fullWidth" light={true} />
        <List>
          <MenuList user={user} history={history} />
        </List>
      </Drawer>
    )
  }
}

const styles = theme => ({
  container: {
    padding: 24
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    flexShrink: 0
  },
  drawerPaper: {
    backgroundColor: DrawerColor,
    color: DrawerColor
  }
})

export const mapStateToProps = state => ({
  drawerStatus: getDrawerStatus(state)
})

const styledComponent = withStyles(styles, { withTheme: true })(AppDrawer)
export default connect(mapStateToProps)(styledComponent)
