import menuItems from './items'
import React, { Fragment } from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
  } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.contrastText
  },
  label: {
    color: theme.palette.primary.contrastText
  }
}))

const hasPermission = (user, permission) =>
  user && user.permissions && user.permissions[permission]

export default function MenuList({ history, user }) {
  const classes = useStyles()
  return (
    <Fragment>
      {menuItems.map(m =>
        hasPermission(user, m.permission) ? (
          <ListItem key={m.route} button onClick={() => history.push(m.route)}>
            <ListItemIcon className={classes.icon}>{m.icon}</ListItemIcon>
            <ListItemText className={classes.label} primary={m.label} />
          </ListItem>
        ) : null
      )}
    </Fragment>
  )
}
