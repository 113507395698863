import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import UserService from '../user/UserService'
import { Grid, CircularProgress } from '@material-ui/core'
import { hmac } from '../utils/crypto'

const Redirect = props => {
  useEffect(() => {
    window.location.href = props.to
  }, [props.to])

  return null
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [token] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const updateUserInfo = async () => {
      const storedUser = localStorage.getItem('user')

      if (!storedUser) {
        return
      }

      try {
        const user = await UserService.userInfo(JSON.parse(storedUser)._key)
        setUser(user)
      } catch (e) {
        console.log(e)
      }
    }

    if (!token) {
      updateUserInfo()
    }
  }, [token])

  let storedToken = localStorage.getItem('token')
  if (!storedToken) {
    return <Redirect to='/#/login' />
  }

  if (!user) {
    return (
      <Grid
        container
        style={{ height: '100vh', width: '100vw' }}
        alignContent='center'
        justify='center'
      >
        <CircularProgress />
      </Grid>
    )
  }

  return <Route {...rest} render={props => <Component {...props} />} />
}

export default ProtectedRoute
