import { SET_ACTIVE_STORE, STORE_FETCH_SUCCESS } from './StoreActions'

import { CONSOLIDATED } from '../Constants'

const initialState = {
  stores: [],
  activeStore: '001',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_FETCH_SUCCESS: {
      if (!action.payload) return state
      return { ...state, stores: action.payload }
    }
    case SET_ACTIVE_STORE: {
      if (!action.payload) return state
      return { ...state, activeStore: action.payload }
    }
    default:
      return state
  }
}

export default reducer

export const getStores = state => state.store.stores
export const getActiveStore = state => state.store.activeStore