import FeatureBar from '../layout/FeatureBar'
import History from '../History'
import LuxonUtils from '@date-io/luxon'
import MainContent from '../layout/MainContent'
import React, { Component, Fragment } from 'react'
import ScheduleService from './ScheduleService'
import Spacer from '../layout/Spacer'
import StoreService from '../store/StoreService'
import {
  Button,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { DateTime } from 'luxon'

class ScheduleEditContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      schedule: {
        start: DateTime.utc().toISODate(),
        end: DateTime.utc().toISODate(),
        stores: [],
        products: 65
      },
      stores: [],
      saving: false
    }
  }

  componentDidMount = () => {
    this.fetchStores()
  }

  fetchStores = () => {
    StoreService.stores().then(stores =>
      this.setState({ stores: stores.map(s => s.store_code) })
    )
  }

  fromISOToDate = iso => {
    const parts = iso.split('-')
    return new Date(parts[0], parts[1] - 1, parts[2])
  }

  createSchedule = () => {
    const { schedule } = this.state

    if (schedule.name === '' || schedule.name === null || !schedule.name) {
      return alert('Digite um nome para a agenda')
    }

    if (schedule.stores.length === 0) {
      return alert('Selecione ao menos uma loja')
    }

    this.setState({ saving: true })

    const start = DateTime.fromISO(schedule.start).startOf('day')
    const end = DateTime.fromISO(schedule.end).startOf('day')
    const now = DateTime.local().startOf('day')

    if (start < now) {
      return alert('A data de início deve ser superior a hoje')
    }

    if (end < start) {
      return alert('A data de encerramento deve ser superior a data de início')
    }

    ScheduleService.create(schedule)
      .then(r => {
        this.setState({ saving: false })
        alert('A agenda de recuperação está em criação, aguarde 5 minutos ')
        this.props.history.push('/recover-schedule')
      })
      .catch(() => this.setState({ saving: false }))
  }

  handleDateChange = name => date => {
    const { schedule } = this.state
    schedule[name] = date.toISODate()
    this.setState({ schedule })
  }

  handleAmountChange = event => {
    const { schedule } = this.state
    schedule.products = +event.target.value
    this.setState({ schedule })
  }

  handleNameChange = event => {
    const { schedule } = this.state
    schedule.name = event.target.value
    this.setState({ schedule })
  }

  render() {
    const { classes } = this.props
    const { schedule, stores, saving } = this.state

    return (
      <Fragment>
        <FeatureBar title="Agenda de recuperação de margem" />
        <MainContent>
          <Grid container>
            <Grid item xs={12}>
              <form noValidate autoComplete="off">
                <Paper elevation={0} className={classes.paper}>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        onChange={this.handleNameChange}
                        id="name"
                        label="Nome da agenda"
                      />
                    </Grid>
                  </Grid>
                  <Spacer double />
                  <Grid item xs={12}>
                    <Typography variant="h6">Período</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <Grid item xs={3}>
                        <DatePicker
                          inputVariant="outlined"
                          id="start"
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          label="Início"
                          value={DateTime.fromISO(schedule.start)}
                          onChange={this.handleDateChange('start')}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DatePicker
                          inputVariant="outlined"
                          id="end"
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          label="Encerramento"
                          value={DateTime.fromISO(schedule.end)}
                          onChange={this.handleDateChange('end')}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Spacer double />
                  <Grid item xs={12}>
                    <Typography variant="h6">Lojas</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {stores &&
                      stores.map(s => (
                        <FormControlLabel
                          key={s}
                          style={{ marginRight: 48 }}
                          control={
                            <Switch
                              checked={schedule.stores.indexOf(s) > -1}
                              onClick={() => {
                                schedule.stores.indexOf(s) == -1
                                  ? schedule.stores.push(s)
                                  : schedule.stores.splice(
                                      schedule.stores.indexOf(s),
                                      1
                                    )
                                this.setState({ schedule })
                              }}
                              value="secondary"
                              label={s}
                            />
                          }
                          label={s}
                        />
                      ))}
                  </Grid>
                  <div style={{ height: 32 }} />
                  <Grid item xs={12}>
                    <Grid item>
                      <TextField
                        variant="outlined"
                        onChange={this.handleAmountChange}
                        defaultValue={65}
                        id="products"
                        label="Quantidade de produtos"
                      />
                    </Grid>
                  </Grid>
                  <Spacer double />
                  {saving ? (
                    <div style={{ height: 52 }}>
                      <LinearProgress color="secondary" />
                    </div>
                  ) : (
                    <Button
                      style={{ marginBottom: 16, marginLeft: 0 }}
                      onClick={this.createSchedule}
                      variant="contained"
                      color="secondary"
                    >
                      Criar agenda
                    </Button>
                  )}
                </Paper>
              </form>
            </Grid>
          </Grid>
        </MainContent>
      </Fragment>
    )
  }
}

const styles = theme => ({
  paper: {
    padding: 24,
    backgroundColor: theme.palette.background.paper
  }
})

export default withStyles(styles)(ScheduleEditContainer)
