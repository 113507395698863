import Api from "../http/ApiService"

class TaskService {
  static users = () => Api.get("/users").then(response => response.data)
  static create = task =>
    Api.post("/tasks/custom", task).then(response => response.data)
  static update = task =>
    Api.post(`/tasks/custom/${task._key}`, task).then(response => response.data)
  static tasks = () => Api.get("/tasks/custom").then(response => response.data)
  static task = key =>
    Api.get(`/tasks/custom/${key}`).then(response => response.data)
  static remove = key =>
    Api.delete(`/tasks/custom/${key}`).then(response => response.data)
}

export default TaskService
