import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead
} from '@material-ui/core'
import { DeleteOutlined, EditOutlined } from '@material-ui/icons'
import React, { Component, Fragment } from 'react'
import FeatureBar from '../layout/FeatureBar'
import MainContent from '../layout/MainContent'
import Spacer from '../layout/Spacer'
import TaskService from './TaskService'

class CustomTaskListContainer extends Component {
  state = {
    tasks: undefined
  }

  componentDidMount = () => {
    TaskService.tasks().then(tasks => this.setState({ tasks }))
  }

  render = () => {
    const { tasks } = this.state
    return (
      <Fragment>
        <FeatureBar title='Tarefas' />
        <MainContent>
          <Grid container>
            <Button
              onClick={() => {
                this.props.history.push('/tasks/edit')
              }}
              variant='contained'
              color='secondary'
            >
              Adicionar Tarefa
            </Button>
          </Grid>
          <Spacer double />
          <TableContainer elevation={0} component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>Tarefa</TableCell>
                  <TableCell align='right'>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Boolean(tasks) &&
                  tasks.map(t => (
                    <TableRow>
                      <TableCell>{t.title}</TableCell>
                      <TableCell align='right'>
                        <IconButton
                          onClick={() =>
                            this.props.history.push(`/tasks/edit/${t._key}`)
                          }
                        >
                          <EditOutlined />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            TaskService.remove(t._key)
                              .then(() => {
                                alert('Tarefa removida')
                                TaskService.tasks().then(tasks =>
                                  this.setState({ tasks })
                                )
                              })
                              .catch(e => alert(e.message))
                          }}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MainContent>
      </Fragment>
    )
  }
}

export default CustomTaskListContainer
