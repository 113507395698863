import { Button, Grid, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component, Fragment } from 'react';
import APIService from '../http/ApiService';
import FeatureBar from '../layout/FeatureBar';
import MainContent from '../layout/MainContent';


const getPriceChangeKey = props => props.match.params && props.match.params.key

class PriceDetailContainer extends Component {

  state = {
    snackbarOpen: false,
  }

  state = {
    priceChange: undefined
  }

  componentDidMount = () => {
    const key = getPriceChangeKey(this.props)
    APIService.get(`/price/changes/${key}`)
      .then(response => response.data)
      .then(priceChange => this.setState({ priceChange }))
      .catch(e => console.log(e))
  }

  handleChangesMade = () => {
    const key = getPriceChangeKey(this.props)
    APIService.post(`/price/changes/${key}`)
      .then(response => {
        this.setState({ snackbarOpen: true })
        this.props.history.push('/precos')
      })
      .catch(e => console.log(e))
  }

  render = () => {
    return (
      <Fragment>
        <FeatureBar history={this.props.history} title="Aterações de Preços" />
        <MainContent>
          <Button
            onClick={this.handleChangesMade}
            variant="contained"
            color="primary"
            className={this.props.classes.saveButton}>
            Alteracoes Concluidas
            </Button>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Descricao</TableCell>
                    <TableCell>Complemento</TableCell>
                    <TableCell>Marca</TableCell>
                    <TableCell>Preco Atual</TableCell>
                    <TableCell>Novo Preco</TableCell>
                  </TableRow>
                </TableHead>
                {this.state.priceChange ?
                  <TableBody>
                    {this.state.priceChange.products.map((product, index) =>
                      <TableRow key={product.codes} className={this.props.classes.row}>
                        <TableCell>{product.description}</TableCell>
                        <TableCell>{product.complements}</TableCell>
                        <TableCell>{product.brand}</TableCell>
                        <TableCell>{product.priceFormatted}</TableCell>
                        <TableCell>{product.suggestedPriceFormatted}</TableCell>
                      </TableRow>
                    )}
                  </TableBody> : null}
              </Table>
            </Paper>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            message="Alteracoes de precos realizadas com sucesso"
            open={this.state.snackbarOpen}
            autoHideDuration={3000}
          />
        </MainContent>
      </Fragment>
    )
  }
}

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  checked: {
    color: '#43A047',
  },
  notChecked: {
    opacity: 0.4,
  },
  saveButton: {
    marginBottom: 24,
  }
})

export default withStyles(styles)(PriceDetailContainer)