import Api from '../http/ApiService'

class RecoverScheduleService {
  static create(payload) {
    return Api.post('/recovers', payload)
      .then(response => response.data)
      .catch(() => null)
  }

  static schedules() {
    return Api.get('/recovers')
      .then(response => response.data)
      .catch(() => null)
  }

  static recover(schedule) {
    return Api.get(`/recovers/${schedule}`)
      .then(response => response.data)
      .catch(() => null)
  }

  static updateProduct(product) {
    return Api.post(`/recovers/products`, product)
      .then(response => response.data)
      .catch(() => null)
  }

  static download = async (schedule, start, end) => {
    return Api.get(`/recovers/${schedule}/${start}/${end}/download`)
      .then(response => response.data)
      .catch(() => null)
  }

  static report = async schedule => {
    return Api.get(`/recovers/report/${schedule}`)
      .then(response => response.data)
      .catch(console.log)
  }

  static remove = recover => {
    return Api.delete(`/recovers/${recover}`)
      .then(response => response.data)
      .catch(() => null)
  }
}

export default RecoverScheduleService
