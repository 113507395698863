import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { Component } from 'react'
import { appSpacing } from '../Styles'
import { Button, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { NavbarBackground } from '../Colors'
import PageTitle from '../typography/page_title'

class FeatureBar extends Component {
  render = () => (
    <Grid direction="row" alignContent="space-between" container>
      <Grid className={this.props.classes.container} item xs={9}>
        {this.props.history ? (
          <Button onClick={() => this.props.history.goBack()}>
            <ArrowBack />
          </Button>
        ) : null}
        <PageTitle title={this.props.title} />
      </Grid>
      {this.props.right ? (
        <Grid
          className={this.props.classes.right}
          container
          justify="flex-end"
          item
          xs={3}
        >
          {this.props.right}
        </Grid>
      ) : (
        <Grid className={this.props.classes.container} item xs={3}></Grid>
      )}
    </Grid>
  )
}

const styles = theme => ({
  container: {
    padding: appSpacing * 4,
    width: '100%',
    height: '96px',
    background: NavbarBackground,
    display: 'inline'
  },
  right: {
    paddingRight: 24
  },
  title: {
    display: 'inline',
    paddingLeft: appSpacing * 2
  }
})

export default withStyles(styles)(FeatureBar)
