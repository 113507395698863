import AppBar from '@material-ui/core/AppBar'
import AppDrawer from './drawer/drawer'
import AppToolBar from './layout/toolbar'
import ClassificationContainer from './classification/ClassificationContainer'
import CreateTaskContainer from './task/CreateTaskContainer'
import CustomTaskListContainer from './task/TaskListContainer'
import DashboardContainer from './dashboard/DashboardContainer'
import GoalsContainer from './goal/GoalsContainer'
import GoalsDepartmentsContainer from './goal/GoalsDepartmentsContainer'
import GoalsEditContainer from './goal/GoalsEditContainer'
import GoalsImportScreen from './goal/import'
import PriceContainer from './price/PriceContainer'
import PriceDetailContainer from './price/PriceDetailContainer'
import PriceScheduleProductsContainer from './price_schedule/PriceScheduleProducts'
import PropTypes from 'prop-types'
import ProtectedRoute from './components/ProtectedRoute'
import QuoteContainer from './quote/QuoteContainer'
import React from 'react'
import RecoverProductsContainer from './recover/RecoverProductsContainer'
import RecoverScheduleEditContainer from './recover_schedule/RecoverScheduleEdit'
import RecoverScheduleListContainer from './recover_schedule/RecoverScheduleList'
import RecoverScheduleProducts from './recover_schedule/RecoverScheduleProducts'
import RecoverSuggestionsContainer from './recover/RecoverSuggestionsContainer'
import RequestsContainer from './request/RequestsContainer'
import ScheduleEditContainer from './price_schedule/ScheduleEdit'
import ScheduleListContainer from './price_schedule/ScheduleList'
import StockoutDepartmentsStats from './stockout/StockoutDepartments'
import StockoutProductsContainer from './stockout/StockoutProductsContainer'
import UserEditContainer from './user/edit'
import UsersContainer from './user/list'
import { AUTHENTICATE_SUCCESS, logout } from './auth/AuthActions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ContentBackground } from './Colors'
import { fetchStores } from './store/StoreActions'
import { getUser } from './auth/AuthReducer'
import { Grid } from '@material-ui/core'
import { store } from './Store'
import { Switch } from 'react-router-dom'
import { UserService } from './user/UserService'
import { withStyles } from '@material-ui/core/styles'
import { RecoverReportContainer } from './recover_schedule/RecoverReport'

const drawerWidth = 240

class MiniDrawer extends React.Component {
  state = {
    open: false
  }

  componentDidMount = async () => {
    const token = localStorage.getItem('token')
    let user = localStorage.getItem('user')

    if (token && user) {
      user = JSON.parse(user)
      const userInfo = await UserService.userInfo(user._key)

      if (!userInfo.active || user.forceLogout) {
        return localStorage.clear()
      }

      this.props.fetchStores()

      store.dispatch({
        type: AUTHENTICATE_SUCCESS,
        payload: {
          user: userInfo,
          token
        }
      })
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  logout = () => {
    this.props.logout()
  }

  render() {
    const { classes, user, history, logout } = this.props
    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar} color="primary">
          <AppToolBar logout={logout} />
        </AppBar>
        <AppDrawer user={user} history={history} />
        <Grid
          style={{ alignContent: 'flex-start', paddingTop: '64px' }}
          container
        >
          <Switch>
            <ProtectedRoute exact path="/" component={DashboardContainer} />
            <ProtectedRoute exact path="/metas" component={GoalsContainer} />
            <ProtectedRoute
              exact
              path="/metas/:key"
              component={GoalsEditContainer}
            />
            <ProtectedRoute
              exact
              path="/metas-departamentos"
              component={GoalsDepartmentsContainer}
            />
            <ProtectedRoute
              exact
              path="/recover-margin"
              component={RecoverScheduleListContainer}
            />
            <ProtectedRoute
              exact
              path="/recover-margin/edit"
              component={RecoverScheduleEditContainer}
            />
            <ProtectedRoute
              exact
              path="/recover-margin/:recover"
              component={RecoverScheduleProducts}
            />
            <ProtectedRoute
              exact
              path="/ruptura"
              component={StockoutDepartmentsStats}
            />
            <ProtectedRoute
              exact
              path="/ruptura/:dep/produtos/:description"
              component={StockoutProductsContainer}
            />
            <ProtectedRoute exact path="/precos" component={PriceContainer} />
            <ProtectedRoute
              exact
              path="/precos/:key"
              component={PriceDetailContainer}
            />
            <ProtectedRoute
              exact
              path="/precos-temporarios/:sale"
              component={RecoverProductsContainer}
            />
            <ProtectedRoute
              exact
              path="/precos-temporarios"
              component={RecoverSuggestionsContainer}
            />
            <ProtectedRoute
              exact
              path="/recover-schedule"
              component={ScheduleListContainer}
            />
            <ProtectedRoute
              exact
              path="/recover-report/:schedule"
              component={RecoverReportContainer}
            />
            <ProtectedRoute
              exact
              path="/recover-schedule/edit"
              component={ScheduleEditContainer}
            />
            <ProtectedRoute
              exact
              path="/recover-schedule/:schedule"
              component={PriceScheduleProductsContainer}
            />
            <ProtectedRoute
              exact
              path="/classificar"
              component={ClassificationContainer}
            />
            <ProtectedRoute
              exact
              path="/requisicoes"
              component={RequestsContainer}
            />
            <ProtectedRoute
              exact
              path="/tasks"
              component={CustomTaskListContainer}
            />
            <ProtectedRoute
              exact
              path="/tasks/edit"
              component={CreateTaskContainer}
            />
            <ProtectedRoute
              exact
              path="/tasks/edit/:key"
              component={CreateTaskContainer}
            />
            <ProtectedRoute exact path="/cotacao" component={QuoteContainer} />
            <ProtectedRoute exact path="/usuarios" component={UsersContainer} />
            <ProtectedRoute
              exact
              path="/usuarios/:key"
              component={UserEditContainer}
            />
            <ProtectedRoute
              exact
              path="/metas-importar"
              component={GoalsImportScreen}
            />
          </Switch>
        </Grid>
      </div>
    )
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    zIndex: 1,
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7)
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    backgroundColor: ContentBackground,
    padding: theme.spacing(4)
  }
})

export const mapStateToProps = state => ({
  user: getUser(state)
})

export const MapDispatchToProps = dispatch => ({
  fetchStores: bindActionCreators(fetchStores, dispatch),
  logout: bindActionCreators(logout, dispatch)
})

const styledComponent = withStyles(styles, { withTheme: true })(MiniDrawer)
export default connect(mapStateToProps, MapDispatchToProps)(styledComponent)
