import { TextHint, TextPrimary, TextSecondary, White } from './Colors'

import { FONT_LIGHT } from './Constants'

export const appSpacing = 8
export const smallerFontSize = 12
export const defaultFontSize = 14
export const largerFontSize = 16
export const largeFontSize = 22
export const lightFont = 'Roboto'

export const Styles = {
  title: {
    fontSize: largeFontSize,
    color: TextSecondary,
  },
  label: {
    color: TextSecondary,
    fontFamily: lightFont,
    fontSize: defaultFontSize,
  },
  trend: {
    fontFamily: lightFont,
    fontSize: defaultFontSize,
    color: TextPrimary,
  },
  body: {
    fontFamily: lightFont,
    fontSize: defaultFontSize,
    color: TextSecondary,
  },
  titleSecondary: {
    fontSize: largerFontSize,
    color: TextSecondary,
  },
  caption: {
    fontSize: smallerFontSize,
    color: TextHint,
    fontFamily: lightFont,
  },
  headlineAmount: {
    fontSize: largeFontSize,
    color: TextPrimary,
  },
  goalLabel: {
    fontFamily: lightFont,
    fontSize: smallerFontSize,
    color: TextSecondary,
  },
  historyValue: {
    padding: appSpacing / 2,
    fontFamily: lightFont,
    fontSize: smallerFontSize,
    textAlign: 'left',
  },
  buttonMore: {
    opacity: 0.57,
  },
  lightText: {
    fontFamily: lightFont,
    color: TextPrimary,
    fontSize: defaultFontSize,
    opacity: 0.57,
  },
  menuLabel: {
    fontFamily: lightFont,
    fontSize: defaultFontSize,
    color: TextPrimary,
  },
  listItem: {
    paddingLeft: appSpacing * 2,
    marginLeft: 0,
    backgroundColor: White,
  }
}