import { PRICE_CHANGES_FETCH_SUCCESS } from './PriceActions'

const initialState = {
  changes: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRICE_CHANGES_FETCH_SUCCESS: {
      return { ...state, changes: action.payload }
    }

    default: {
      return state
    }
  }
}

export default reducer

export const getPriceChanges = state => state.price.changes