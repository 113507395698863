import { AUTHENTICATE, AUTHENTICATE_SUCCESS, LOGOUT } from './AuthActions'
import { call, put, takeLatest } from 'redux-saga/effects'

import AuthService from './AuthService'
import history from '../History'

function* authenticate(action) {
  const { email, password } = action.payload

  try {
    const response = yield call(AuthService.authenticate, email, password)

    if (response.data.token && response.data.user) {
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data.user))

      yield put({ type: AUTHENTICATE_SUCCESS, payload: response.data })
      history.push('/')
    }
  } catch (e) {}
}

function* logout() {
  yield localStorage.removeItem('token')
  yield localStorage.removeItem('user')
  yield history.push('/login')
}

export default [
  takeLatest(AUTHENTICATE, authenticate),
  takeLatest(LOGOUT, logout)
]
