export const getJsDateFromExcel = excelDate => {
  if (!Number(excelDate)) {
    throw new Error('wrong input format')
  }

  const secondsInDay = 24 * 60 * 60
  const missingLeapYearDay = secondsInDay * 1000
  const delta = excelDate - (25567 + 2)
  const parsed = delta * missingLeapYearDay
  const date = new Date(parsed)

  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.getTime())) {
      throw new Error('wrong excel date input')
    } else {
      return date
    }
  }
}
