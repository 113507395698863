import { SET_ACTIVE_STORE, STORE_FETCH, STORE_FETCH_SUCCESS } from './StoreActions'
import { call, put, takeLatest } from 'redux-saga/effects'

import StoreService from './StoreService'

function* fetchStores() {
  try {
    const response = yield call(StoreService.stores)

    yield put({ type: STORE_FETCH_SUCCESS, payload: response })
  } catch (e) {

  }
}

function* setActiveStore(action) {
  yield localStorage.setItem('activeStore', action.payload)
}

export default [
  takeLatest(STORE_FETCH, fetchStores),
  takeLatest(SET_ACTIVE_STORE, setActiveStore),
]