import Api from '../http/ApiService'

export class ClassificationService {
  static products = () =>
    Api.get('/classification/products/custom')
      .then(response => response.data)

  static groups = () =>
    Api.get(`/classification/groups`)
      .then(response => response.data)

  static saveProducts = products =>
    Api.post('/classification/products/custom', products)
      .then(response => response.data)
  
  static count = () =>
      Api.get('/classification/products/custom/count')
        .then(response => response.data)
}

export default ClassificationService