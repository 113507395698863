import Dates from '../utils/date'
import FeatureBar from '../layout/FeatureBar'
import MainContent from '../layout/MainContent'
import React, { Component, Fragment } from 'react'
import ScheduleService from './ScheduleService'
import Spacer from '../layout/Spacer'
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'
import { default as history, default as History } from '../History'
import { RemoveCircleOutline, ViewAgendaTwoTone } from '@material-ui/icons'
import Formatter from '../utils/Formatter'

class ScheduleListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      schedules: undefined
    }
  }

  componentDidMount = () => {
    this.fetchSchedules()
  }

  fetchSchedules = () => {
    ScheduleService.schedules().then(schedules => this.setState({ schedules }))
  }

  removeSchedule = schedule => {
    const response = window.confirm(
      'Deseja realmente remover esta agenda de recuperação?'
    )
    if (response) {
      ScheduleService.remove(schedule).then(this.fetchSchedules)
    }
  }

  render() {
    const { schedules } = this.state
    return (
      <Fragment>
        <FeatureBar title={'Agenda de Recuperação'} />
        <MainContent>
          <Button
            onClick={() => this.props.history.push('/recover-schedule/edit')}
            variant="contained"
            color="secondary"
          >
            Criar Agenda
          </Button>
          <Spacer double />
          <Paper elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Início</TableCell>
                  <TableCell>Encerramento</TableCell>
                  <TableCell>Lojas</TableCell>
                  <TableCell align="right">Resultado</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedules &&
                  schedules.map(s => (
                    <TableRow key={s.schedule}>
                      <TableCell>{s.name}</TableCell>
                      <TableCell>{Dates.format(s.start)}</TableCell>
                      <TableCell>{Dates.format(s.end)}</TableCell>
                      <TableCell>{s.stores.join(';')}</TableCell>
                      <TableCell align="right">
                        {Formatter.currency(s.achieved)}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip placement="top" title="Visualizar Produtos">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              this.props.history.push(`recover-schedule/${s.schedule}`)
                            }
                          >
                            <ViewAgendaTwoTone />
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Remover">
                          <IconButton
                            onClick={() => this.removeSchedule(s.schedule)}
                          >
                            <RemoveCircleOutline color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </MainContent>
      </Fragment>
    )
  }
}

export default ScheduleListContainer
