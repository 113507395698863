import Dates from '../utils/date'
import FeatureBar from '../layout/FeatureBar'
import Formatter from '../utils/Formatter'
import history from '../History'
import History from '../History'
import MainContent from '../layout/MainContent'
import React, { Component, Fragment } from 'react'
import RecoverScheduleService from './RecoverScheduleService'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'
import Spacer from '../layout/Spacer'
import {
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core'
import {
  RemoveCircleOutline,
  ViewAgendaTwoTone,
  LocalAtmOutlined
} from '@material-ui/icons'

class RecoverScheduleListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      schedules: undefined
    }
  }

  componentDidMount = () => {
    this.fetchSchedules()
  }

  fetchSchedules = () => {
    RecoverScheduleService.schedules().then(schedules =>
      this.setState({
        schedules,
        total: schedules.reduce(
          (total, b) => total + Number.parseFloat(b.achieved),
          0
        )
      })
    )
  }

  removeSchedule = recover => {
    const response = window.confirm(
      'Deseja realmente remover esta agenda de recuperação?'
    )
    if (response) {
      RecoverScheduleService.remove(recover).then(this.fetchSchedules)
    }
  }

  render() {
    const { schedules, total = 0 } = this.state
    return (
      <Container maxWidth="lg">
        <FeatureBar title={'Recuperação de Margem'} />
        <MainContent>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                onClick={() => this.props.history.push('/recover-margin/edit')}
                variant="contained"
                color="secondary"
              >
                Criar Agenda
              </Button>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                Total: {Formatter.currency(total)}
              </Typography>
            </Grid>
          </Grid>
          <Spacer double />
          <Paper elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Início</TableCell>
                  <TableCell>Encerramento</TableCell>
                  <TableCell>Lojas</TableCell>
                  <TableCell align="right">Resultado</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schedules && schedules.length > 0 ? (
                  schedules.map(s => (
                    <TableRow key={s.schedule}>
                      <TableCell>{s.name}</TableCell>
                      <TableCell>{Dates.format(s.start)}</TableCell>
                      <TableCell>{Dates.format(s.end)}</TableCell>
                      <TableCell>{s.stores.join(';')}</TableCell>
                      <TableCell align="right">
                        {Formatter.currency(s.achieved)}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip placement="top" title="Visualizar Produtos">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              this.props.history.push(
                                `recover-margin/${s.schedule}`
                              )
                            }
                          >
                            <ViewAgendaTwoTone />
                          </IconButton>
                        </Tooltip>
                        <Tooltip>
                          <IconButton
                            color="primary"
                            onClick={() =>
                              this.props.history.push(
                                `recover-report/${s.schedule}`
                              )
                            }
                          >
                            <LocalAtmOutlined />
                          </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="Remover">
                          <IconButton
                            onClick={() => this.removeSchedule(s.schedule)}
                          >
                            <RemoveCircleOutline color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Grid style={{ padding: 24, textAlign: 'center' }}>
                        <Typography variant="body1">
                          Crie a sua primeira recuperação de margem clicando no
                          botão "Criar Agenda"
                        </Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </MainContent>
      </Container>
    )
  }
}

export default RecoverScheduleListContainer
