import { GOALS_MONTH_FETCH_SUCCESS, GOALS_DAILY_FETCH_SUCCESS } from './GoalsActions'

const initialState = {
  month: {},
  daily: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GOALS_MONTH_FETCH_SUCCESS: {
      if (!action.payload.store) return
      return { ...state, month: { ...state.month, [action.payload.store]: action.payload } }
    }
    case GOALS_DAILY_FETCH_SUCCESS: {
      if (!action.payload.store) return
      return { ...state, daily: { ...state.daily, [action.payload.store]: action.payload.goals } }
    }
    default:
      return state
  }
}

export default reducer

export const getGoalsMonth = state => state.goals.month
export const getGoalsDaily = state => state.goals.daily 