import React from 'react'
import { appSpacing } from '../Styles'
import { withStyles } from '@material-ui/core/styles'

const CardSection = ({ classes, children }) =>
  <div className={classes.paper}>
    {children}
  </div>

const styles = theme => ({
  paper: {
    padding: appSpacing * 2,
    paddingHorizontal: appSpacing * 3,
  },
})

export default withStyles(styles)(CardSection)