import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  primary: {
    color: theme.palette.primary.main
  },
  contrast: {
    color: theme.palette.primary.contrastText
  }
}))
export default function TypoBody({ label, inverse }) {
  const classes = useStyles()
  return (
    <Typography
      className={inverse ? classes.contrast : classes.primary}
      variant="body1"
    >
      {label}
    </Typography>
  )
}
