import App from './App'
import CssBaseline from '@material-ui/core/CssBaseline'
import deepOrange from '@material-ui/core/colors/deepOrange'
import history from './History'
import LoginContainer from './login/LoginContainer'
import ProtectedRoute from './components/ProtectedRoute'
import React from 'react'
import ReactDOM from 'react-dom'
import { ContentBackground, Primary } from './Colors'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './Store'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: Primary,
      main: Primary,
      dark: '#3D5466',
      contrastText: '#fff'
    },
    background: {
      default: ContentBackground
    },
    text: {
      primary: '#1B3A57'
    },
    secondary: deepOrange
  },
  status: {
    danger: 'orange'
  }
})

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <HashRouter basename="/" history={history}>
        <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <ProtectedRoute path="/" component={App} />
        </Switch>
      </HashRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
)
