import Api from "../http/ApiService"

export class QuoteService {
  static quotes = store =>
    Api.get(`/quote/exported/${store}`).then(response => response.data)

  static products = store =>
    Api.get(`/quote/products/${store}`).then(response => response.data)

  static download = key =>
    Api.get(`/quote/download/${key}`).then(response => response.data)

  static createRequest = store =>
    Api.post(`/quote/create/${store}`).then(response => response.data)
}

export default QuoteService
