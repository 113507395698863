import FeatureBar from "../layout/FeatureBar"
import React, { Fragment, Component } from "react"
import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableCell,
  Card,
  Button,
  TableBody,
  TableHead,
  IconButton
} from "@material-ui/core"
import Spacer from "../layout/Spacer"
import QuoteService from "./QuoteService"
import Download from "@material-ui/icons/CloudDownload"
import StoreService from "../store/StoreService"

class QuoteContainer extends Component {
  state = {
    requests: undefined,
    products: undefined,
    selectedStore: 0,
    stores: [],
    fetching: false
  }

  componentDidMount = () => {
    this.fetchStores()
  }

  fetchStores = () => {
    StoreService.stores()
      .then(stores => {
        this.setState({ stores }, () => {
          this.fetchQuotes()
          this.fetchProducts()
        })
      })
      .catch()
  }

  fetchQuotes = () => {
    const storeCode = this.state.stores[this.state.selectedStore].store_code
    QuoteService.quotes(storeCode).then(requests => this.setState({ requests }))
  }

  fetchProducts = () => {
    const storeCode = this.state.stores[this.state.selectedStore].store_code
    QuoteService.products(storeCode).then(products =>
      this.setState({ products })
    )
  }

  formatDate = date => {
    const year = date.substr(0, 4)
    const month = date.substr(5, 2)
    const day = date.substr(8, 2)
    return `${day}/${month}/${year}`
  }

  createRequest = () => {
    if (this.state.products.length == 0) {
      return alert("Nenhum produto para gerar a requisição")
    }

    const answer = window.confirm(
      "Deseja gerar uma requisição com esses produtos?"
    )
    if (answer == true) {
      const storeCode = this.state.stores[this.state.selectedStore].store_code
      QuoteService.createRequest(storeCode).then(() => {
        this.fetchQuotes()
        this.fetchProducts()
      })
    }
  }

  downloadRequest = async request => {
    const products = await QuoteService.download(request.code).catch()

    if (!products || products.length == 0) {
      return
    }

    const store = this.state.stores[this.state.selectedStore]
    const storeName = store.short_name.replace(" ", "_").toLowerCase()
    const element = document.createElement("a")
    const file = new Blob(products, { type: "text/plain" })
    element.href = URL.createObjectURL(file)
    element.download = `cotação-${storeName}-${request.date}.txt`
    element.click()
  }

  render = () => {
    const { stores, selectedStore } = this.state
    return (
      <Fragment>
        <FeatureBar title="Cotação" />
        <Grid container spacing={8} direction="row" style={{ margin: 36 }}>
          {stores.map((s, index) => {
            return (
              <Grid item>
                <Button
                  onClick={() => {
                    this.setState({ selectedStore: index }, () => {
                      this.fetchProducts()
                      this.fetchQuotes()
                    })
                  }}
                  color={selectedStore === index ? "secondary" : "default"}
                >
                  {s.short_name}
                </Button>
              </Grid>
            )
          })}
        </Grid>
        {stores.length === 0 ? (
          <Grid
            alignItems="center"
            alignContent="center"
            textAlign="center"
            container
          >
            <Typography style={{ marginLeft: 64 }} variant="body2">
              Selecione uma loja
            </Typography>
          </Grid>
        ) : (
          <Grid
            style={{ marginTop: 36 }}
            container
            direction="row"
            item
            justify="center"
            xs={12}
          >
            <Grid item xs={6}>
              <Grid container direction="row" justify="space-between">
                <Typography variant="subtitle2">
                  Produtos {(stores[selectedStore] || {}).short_name}
                </Typography>
                <Button
                  onClick={this.createRequest}
                  variant="contained"
                  color="secondary"
                >
                  Gerar Cotação
                </Button>
              </Grid>
              <Spacer double />
              <Card>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Produto</TableCell>
                      <TableCell>Quantidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.products &&
                      this.state.products.map(p => (
                        <TableRow>
                          <TableCell>
                            {p.description} {p.complement} {p.brand}
                          </TableCell>
                          <TableCell>{p.amount}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
            <Grid style={{ marginLeft: 24 }} item xs={5}>
              <Typography variant="subtitle2">
                Cotações {(stores[selectedStore] || {}).short_name}
              </Typography>
              <Spacer double />
              <Card>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Produtos</TableCell>
                      <TableCell>Baixar Lista</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.requests &&
                      this.state.requests.map(r => (
                        <TableRow>
                          <TableCell>{this.formatDate(r.date)}</TableCell>
                          <TableCell>{r.count}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => this.downloadRequest(r)}>
                              <Download />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        )}
      </Fragment>
    )
  }
}

export default QuoteContainer
