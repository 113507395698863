import { IconButton, TableCell, TableRow, Typography } from '@material-ui/core'
import React, { Fragment } from 'react'

import { DateTime } from 'luxon'
import EditIcon from '@material-ui/icons/Edit'
import Formatter from '../utils/Formatter'
import { withStyles } from '@material-ui/core/styles'
import { ListAlternateBackgroundColor } from '../Colors'

const GoalTableItem = ({ goal, month, onEdit, classes }) => (
  <TableRow className={classes.row}>
    <TableCell>
      {month ? (
        <Typography variant="body1">
          {DateTime.fromISO(goal.date).toFormat('MM/yyyy')}
        </Typography>
      ) : (
        <Typography variant="body1">
          {DateTime.fromISO(goal.date).toFormat('dd/MM/yyyy')}
        </Typography>
      )}
    </TableCell>
    <TableCell>
      <Typography variant="body1">
        {Formatter.currency(goal.revenue)}
      </Typography>
    </TableCell>
    <TableCell>{Formatter.currency(goal.profit)}</TableCell>
    {month ? (
      <Fragment>
        <TableCell>{Formatter.currency(goal.purchases)}</TableCell>
        <TableCell>{Formatter.currency(goal.averageTicket)}</TableCell>
      </Fragment>
    ) : null}
    <TableCell>
      <IconButton onClick={() => onEdit(goal._key)}>
        <EditIcon />
      </IconButton>
    </TableCell>
  </TableRow>
)

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: ListAlternateBackgroundColor
    }
  }
})

export default withStyles(styles)(GoalTableItem)
