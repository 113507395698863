import {
  STOCKOUT_PRODUCTS_FETCH,
  STOCKOUT_PRODUCTS_FETCH_SUCCESS,
  STOCKOUT_PRODUCT_SAVE,
} from './StockoutActions'
import { call, put, takeLatest } from 'redux-saga/effects'

import { StockoutService } from './StockoutService'

function* fetchStockoutProducts() {
  try {
    const response = yield call(StockoutService.products)
    if (response.data) {
      yield put({ type: STOCKOUT_PRODUCTS_FETCH_SUCCESS, payload: response.data })
    }
  } catch (e) {

  }
}

function* saveStockoutProduct(action) {
  try {
    yield call(StockoutService.saveProduct, action.payload)
  } catch (e) {
    console.log('Error saving product')
  }
}

export default [
  takeLatest(STOCKOUT_PRODUCTS_FETCH, fetchStockoutProducts),
  takeLatest(STOCKOUT_PRODUCT_SAVE, saveStockoutProduct),
]