import React from 'react'
import { createMuiTheme, ThemeProvider, Typography } from '@material-ui/core'
import { TextPrimary } from '../Colors'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Open Sans"'].join(',')
  }
})

export default function PageTitle({ title }) {
  return (
    <ThemeProvider theme={theme}>
      <Typography style={{ color: TextPrimary }} variant="h4">
        {title}
      </Typography>
    </ThemeProvider>
  )
}
