import EditIcon from '@material-ui/icons/Edit'
import FeatureBar from '../layout/FeatureBar'
import Formatter from '../utils/Formatter'
import GoalsService from './GoalsService'
import MainContent from '../layout/MainContent'
import React, { Component, Fragment } from 'react'
import NumberFormat from 'react-number-format'
import { bindActionCreators } from 'redux'
import {
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Modal,
  CardContent,
  Typography,
  TextField,
  Button
} from '@material-ui/core'
import { connect } from 'react-redux'
import { getActiveStore, getStores } from '../store/StoreReducer'
import { setActiveStore } from '../store/StoreActions'
import Spacer from '../layout/Spacer'
import SaveIcon from '@material-ui/icons/Save'
import { ListAlternateBackgroundColor } from '../Colors'

class GoalsDepartmentsContainer extends Component {
  state = {
    goals: undefined,
    goal: undefined,
    opened: false
  }

  componentDidMount = () => {
    this.fetchGoals()
  }

  fetchGoals = () => {
    GoalsService.departmentGoals(this.props.activeStore).then(goals =>
      this.setState({ goals: goals.goals })
    )
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.activeStore !== this.props.activeStore) {
      this.fetchGoals(nextProps.activeStore)
    }
  }

  onEdit = goal => this.setState({ goal, opened: true })

  handleStoreChange = e => {
    this.props.setActiveStore(e.target.value)
  }

  handleClose = () => {
    this.setState({ opened: false })
    this.fetchGoals()
  }

  handleRevenueChange = e => {
    const { goal } = this.state
    goal.revenue = e.floatValue || 0.1
    this.setState({ goal })
  }

  onCloseEdit = () => {
    this.setState({ goal: undefined, opened: false })
  }

  save = () => {
    const { goal } = this.state
    const { activeStore } = this.props
    GoalsService.updateDepartmentGoal(activeStore, goal).then(() =>
      this.fetchGoals()
    )
  }

  render = () => {
    const { goals } = this.state
    const { classes } = this.props
    const { goal } = this.state
    return (
      <Fragment>
        {goal && (
          <Modal open={this.state.opened} onClose={this.handleClose}>
            <div style={getModalStyle()} className={this.props.classes.paper}>
              <Fragment>
                <MainContent>
                  <CardContent>
                    <Typography variant="subtitle2">
                      {goal && goal.departmentDescription}
                    </Typography>
                    <Spacer />
                    <Typography variant="caption">
                      Atenção: Defina o valor aproximado da meta mensal para o
                      departamento. Esse valor sera ajustado conforme a
                      distribuição de vendas diária
                    </Typography>
                    <NumberFormat
                      id="revenue"
                      label="Meta Mensal R$"
                      margin="normal"
                      className={this.props.classes.input}
                      customInput={TextField}
                      value={goal.revenue}
                      onValueChange={this.handleRevenueChange}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={'R$ '}
                    />
                    <Spacer size={16} />
                    <Button
                      onClick={this.save}
                      variant="contained"
                      color="secondary"
                      className={this.props.classes.button}
                    >
                      <SaveIcon className={this.props.classes.leftIcon} />
                      Salvar
                    </Button>
                    <Button
                      onClick={() => this.onCloseEdit()}
                      className={this.props.classes.button}
                    >
                      Cancelar
                    </Button>
                  </CardContent>
                </MainContent>
              </Fragment>
            </div>
          </Modal>
        )}
        <FeatureBar title="Meta Departamentos - Mensal" />
        <MainContent>
          <Grid item xs={12}>
            <FormControl style={{ paddingBottom: 36 }}>
              <InputLabel htmlFor="store">Loja</InputLabel>
              <Select
                value={this.props.activeStore}
                onChange={this.handleStoreChange}
                inputProps={{
                  name: 'store',
                  id: 'store'
                }}
              >
                {this.props.stores &&
                  this.props.stores.map(s => (
                    <MenuItem key={s.store_code} value={s.store_code}>
                      {s.short_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Card elevation={0}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Setor</TableCell>
                      <TableCell>Departamento</TableCell>
                      <TableCell>Meta</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  {goals ? (
                    <TableBody>
                      {goals.map(g => (
                        <TableRow key={g.department} className={classes.row}>
                          <TableCell>{g.sectorDescription}</TableCell>
                          <TableCell>{g.departmentDescription}</TableCell>
                          <TableCell>{Formatter.currency(g.revenue)}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => this.onEdit(g)}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : null}
                </Table>
              </Card>
            </Grid>
          </Grid>
        </MainContent>
      </Fragment>
    )
  }
}

function getModalStyle() {
  const top = window.innerHeight / 2 - 300
  const left = window.innerWidth / 2 - 200

  return {
    top: `${top}px`,
    left: `${left}px`
  }
}

const styles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: ListAlternateBackgroundColor
    }
  },
  input: {
    marginRight: 24
  },
  saveButton: {
    marginTop: 24
  },
  leftIcon: {
    marginRight: 16
  },
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper
  }
})

const mapStateToProps = state => ({
  stores: getStores(state),
  activeStore: getActiveStore(state)
})

const mapDispatchToProps = dispatch => ({
  setActiveStore: bindActionCreators(setActiveStore, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GoalsDepartmentsContainer))
