import { Button, Grid, Typography, Paper, Table, TableRow, TableCell, TableBody, Snackbar } from '@material-ui/core'
import { CloudDownload, Done, AttachMoney } from '@material-ui/icons'
import { RecoverService } from './RecoverService'
import { ROUTE_RECOVER_PRODUCTS } from '../Constants'
import { withStyles } from '@material-ui/core/styles'
import FeatureBar from '../layout/FeatureBar'
import history from '../History'
import MainContent from '../layout/MainContent'
import NoResults from '../common/NoResults'
import React, { Component, Fragment } from 'react'

class RecoverSuggestionsContainer extends Component {

  state = {
    suggestions: undefined,
    snackbarOpen: false,
    snackbarMessage: '',
  }

  componentDidMount = () => {
    this.fetchRecoverSuggestions()
  }

  fetchRecoverSuggestions = () => {
    RecoverService.suggestions()
      .then(suggestions => this.setState({ suggestions }))
  }

  handleSuggestionSelection = (code, description) =>
    this.props.history.push({ pathname: `${ROUTE_RECOVER_PRODUCTS}/${code}`, state: { description } })

  downloadPriceChanges = async sale => {
    const products = await RecoverService.download(sale)
      .catch(e => console.log(e))

    if (!products) {
      return
    }

    const element = document.createElement("a")
    const file = new Blob(products, { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = `oferta-${sale}-recuperacao.txt`
    element.click()
  }

  markAsDone = sale => {
    RecoverService.markAsDone(sale)
      .then(() => {
        this.setState({ snackbarOpen: true, snackbarMessage: 'Agenda finalizada' })
        this.fetchRecoverSuggestions()
      })
  }

  goToProducts = sale => {
   this.props.history.push(`/precos-temporarios/${sale}`)
  }

  onClose = () => {
    this.setState({ snackbarOpen: false })
  }

  render = () => {
    return (
      <Fragment>
        <FeatureBar title="Preços Temporários" />
        <MainContent>
          <Grid item xs={12}>
            {this.state.suggestions ?
              <Paper elevation={1}>
                <Table>
                  <TableBody>
                    {
                      this.state.suggestions.map(s =>
                        <TableRow style={s.status === 'complete' ? { opacity: 0.5 } : {}} key={s._key} className={this.props.classes.row}>
                          <TableCell style={{ padding: 16, flex: 1 }}>
                            <Grid container direction="row" justify="space-between">
                              <Grid item>
                                <Typography variant="subtitle2" component="h2">
                                  {s.description}
                                </Typography>
                                <Typography className={this.props.classes.title} color="textSecondary">
                                  {s.startDateFormatted} a {s.endDateFormatted} {s.status === 'complete' ? ' - Status: Finalizada' : ''}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button onClick={() => this.goToProducts(s.sale)} style={{ marginRight: 16 }} size="small">
                                  <AttachMoney style={{ marginRight: 8, fontSize: 20, color: '#9E9E9E' }} />
                                  Ver preços
                                </Button>
                                <Button style={{ margin: 8 }} onClick={() => this.downloadPriceChanges(s.sale)} size="small">
                                  <CloudDownload style={{ marginRight: 8, fontSize: 20, color: '#2196F3' }} />
                                  Download
                                </Button>
                                <Button onClick={() => this.markAsDone(s.sale)} style={{ marginRight: 16 }} size="small">
                                  <Done style={{ marginRight: 8, fontSize: 20, color: '#009688' }} />
                                  Finalizar
                                </Button>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </Paper>
              : <NoResults message="Nenhuma sugestão de recuperação de margem" />}
          </Grid>
        </MainContent>
        <LocalSnackbar
          open={this.state.snackbarOpen}
          message={this.state.snackbarMessage}
          onClose={this.onClose}
        />
      </Fragment>
    )
  }
}

const styles = {
  card: {
    minWidth: 275,
    marginRight: 16,
    maxWidth: 300,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  headline: {
    marginBottom: 24,
  },
  pos: {
    marginBottom: 12,
  },
}

const LocalSnackbar = ({ open, message, onClose }) =>
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={open}
    autoHideDuration={4000}
    onClose={onClose}
    message={<span id="message-id">{message}</span>}
  />

export default withStyles(styles)(RecoverSuggestionsContainer)