import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button
} from '@material-ui/core'
import React, { Component, Fragment } from 'react'
import { getActiveStore, getStores } from '../store/StoreReducer'

import FeatureBar from '../layout/FeatureBar'
import GoalTableItem from './GoalTableItem'
import GoalsEditContainer from './GoalsEditContainer'
import { GoalsService } from './GoalsService'
import MainContent from '../layout/MainContent'
import Select from '@material-ui/core/Select'
import Spacer from '../layout/Spacer'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setActiveStore } from '../store/StoreActions'
import { withStyles } from '@material-ui/core/styles'

class GoalsContainer extends Component {
  state = {
    dailyGoals: undefined,
    monthGoals: undefined,
    fetchingMonth: true,
    fetchingDaily: true,
    opened: false,
    editKey: undefined
  }

  componentDidMount = () => {
    this.fetchMonthGoal(this.props.activeStore)
    this.fetchDailyGoals(this.props.activeStore)
  }

  saveMonthGoals = () => {}

  handleChange = event => {}

  handleEditGoal = key => {}

  componentWillReceiveProps = nextProps => {
    if (nextProps.activeStore !== this.props.activeStore) {
      this.fetchDailyGoals(nextProps.activeStore)
      this.fetchMonthGoal(nextProps.activeStore)
    }
  }

  handleStoreChange = e => this.props.setActiveStore(e.target.value)

  handleClose = () => {
    this.setState({ opened: false })
    this.fetchDailyGoals()
    this.fetchMonthGoal()
  }

  onEdit = editKey => this.setState({ editKey, opened: true })

  fetchMonthGoal = store =>
    GoalsService.month(store || this.props.activeStore)
      .then(monthGoals => this.setState({ monthGoals, fetchingMonth: false }))
      .catch(e => console.log(e))

  fetchDailyGoals = store =>
    GoalsService.daily(store || this.props.activeStore)
      .then(response => response.goals)
      .then(dailyGoals => this.setState({ dailyGoals, fetchingDaily: false }))
      .catch(e => console.log(e))

  recalculateMonth = () => {
    const goal = this.state.monthGoals
    GoalsService.recalculateMonth(goal._key)
      .then(async () => {
        await this.fetchMonthGoal(this.props.activeStore)
        alert('Meta mensal recalculada')
      })
      .catch(e => {
        console.log(e)
        alert('Erro ao salvar meta mensal')
      })
  }

  render = () => {
    const monthGoals = this.state.monthGoals
    return (
      <Fragment>
        <Modal open={this.state.opened} onClose={this.handleClose}>
          <div style={getModalStyle()} className={this.props.classes.paper}>
            <GoalsEditContainer
              onClose={this.handleClose}
              goal={this.state.editKey}
            />
          </div>
        </Modal>
        <FeatureBar title="Metas" />
        <MainContent>
          <Grid justify="space-between" direction="row" container>
            <Grid item xs={3}>
              <FormControl variant="outlined" style={{ paddingBottom: 36 }}>
                <InputLabel htmlFor="store">Loja</InputLabel>
                <Select
                  value={this.props.activeStore}
                  onChange={this.handleStoreChange}
                  inputProps={{ name: 'store', id: 'store' }}
                  label="Loja"
                >
                  {this.props.stores &&
                    this.props.stores.map(s => (
                      <MenuItem key={s.store_code} value={s.store_code}>
                        {s.short_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container justify="flex-end" item xs={3}>
              <Grid item>
                <Button
                  onClick={this.recalculateMonth}
                  variant="contained"
                  color="secondary"
                >
                  Recalcular Meta Mensal
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography variant="h6">Meta Mensal</Typography>
              </Grid>
            </Grid>
            <Spacer size={16} />
            <Card elevation={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Faturamento</TableCell>
                    <TableCell>Lucro</TableCell>
                    <TableCell>Compras</TableCell>
                    <TableCell>Ticket Médio</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                {monthGoals ? (
                  <TableBody>
                    <GoalTableItem
                      onEdit={this.onEdit}
                      month={true}
                      goal={monthGoals}
                    />
                  </TableBody>
                ) : null}
              </Table>
            </Card>
            <Spacer size={16} />
          </Grid>
          <Grid item xs={12}>
            <Spacer double />
            <Spacer double />
            <Typography variant="h6">Meta Diária</Typography>
            <Spacer size={16} />
            <Card elevation={0}>
              {this.state.dailyGoals ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Faturamento</TableCell>
                      <TableCell>Lucro</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.dailyGoals.map(goal => (
                      <GoalTableItem
                        onEdit={this.onEdit}
                        key={goal._key}
                        goal={goal}
                      />
                    ))}
                  </TableBody>
                </Table>
              ) : null}
            </Card>
          </Grid>
        </MainContent>
      </Fragment>
    )
  }
}

function getModalStyle() {
  const top = window.innerHeight / 2 - 300
  const left = window.innerWidth / 2 - 200

  return {
    top: `${top}px`,
    left: `${left}px`
  }
}

const styles = theme => ({
  input: {
    marginRight: 24
  },
  saveButton: {
    marginTop: 24
  },
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper
  }
})

const mapStateToProps = state => ({
  stores: getStores(state),
  activeStore: getActiveStore(state)
})

const mapDispatchToProps = dispatch => ({
  setActiveStore: bindActionCreators(setActiveStore, dispatch)
})

const StyledGoalsContainer = withStyles(styles)(GoalsContainer)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledGoalsContainer)
