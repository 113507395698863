import Http from './HttpService'

const hostname = window && window.location && window.location.hostname
export let API_URL

if (hostname === 'app.atlasbi.com.br') {
  API_URL = 'https://api.atlasbi.com.br'
} else {
  API_URL = 'https://api.atlasbi.com.br'
}

class APIService {
  static get = url => Http.get(`${API_URL}${url}`)

  static post = (url, payload) => Http.post(`${API_URL}${url}`, payload)

  static put = (url, payload) => Http.put(`${API_URL}${url}`, payload)

  static delete = (url, payload) => Http.delete(`${API_URL}${url}`)
}

export default APIService
