import { Grid, IconButton, TextField, Typography } from '@material-ui/core'
import CallMadeIcon from '@material-ui/icons/CallMade'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'
import UndoIcon from '@material-ui/icons/UndoOutlined'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import RecoverScheduleService from './RecoverScheduleService'
import Formatter from '../utils/Formatter'
import _ from 'underscore'

export const ProductPriceItem = ({ onUpdate, product, index }) => {
  const suggestion = product.suggestions[index]

  const suggestedPrice = suggestion?.suggestedPrice || '0'

  const [suggested, setSuggested] = useState(
    suggestedPrice?.toString().replace('.', ',') || 0
  )

  const handleBlurEvent = () => {
    suggestion.suggestedPrice = +suggested.toString().replace(',', '.')
    product.suggestions[index] = suggestion
    onUpdate(product)
    RecoverScheduleService.updateProduct(product)
  }

  const disableSuggestion = () => {
    suggestion.removed = true
    product.suggestions[index] = suggestion
    onUpdate(product)
    RecoverScheduleService.updateProduct(product)
  }

  const enableSuggestion = () => {
    suggestion.removed = false
    product.suggestions[index] = suggestion
    onUpdate(product)
    RecoverScheduleService.updateProduct(product)
  }

  const onSelectPrice = price => {
    suggestion.suggestedPrice = parseFloat(price)
    suggestion.suggestedPriceFormatted = Formatter.currency(
      parseFloat(price),
      2
    )
    product.suggestions[index] = { ...suggestion }
    setSuggested(suggestion?.suggestedPrice?.toString().replace('.', ',') || 0)
    onUpdate(product)
    RecoverScheduleService.updateProduct(product)
  }

  if (!suggestion) {
    return null
  }

  let pesquisa = []
  if (suggestion.pesquisa && suggestion.pesquisa.length > 0) {
    suggestion.pesquisa = suggestion.pesquisa.filter(p => p.price !== '0.00')
    pesquisa = _.uniq(
      suggestion.pesquisa,
      s => `${s.date + s.competition + s.price}`
    )
  }

  return (
    <>
      <Grid
        container
        wrap="nowrap"
        style={{
          alignItems: 'center',
          background: 'white',
          borderRadius: 16,
          padding: 8,
          marginTop: 8,
          paddingRight: 16,
          width: '100%',
          opacity: suggestion.removed ? 0.4 : 1
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h6">{suggestion.store}</Typography>
        </Grid>
        <Grid item xs={12}>
          {suggestion.priceFormatted}
        </Grid>
        <Grid item xs={12}>
          <TextField
            onBlur={handleBlurEvent}
            onChange={e => setSuggested(e.target.value)}
            onFocus={event => event.target.select()}
            style={{
              maxWidth: 60,
              background: '#EFEFEF',
              padding: 8,
              minWidth: 80
            }}
            value={suggested}
          />
        </Grid>
        <Grid item xs={12}>
          {suggestion.removed ? (
            <IconButton onClick={enableSuggestion}>
              <UndoIcon color="primary" />
            </IconButton>
          ) : (
            <IconButton tabIndex={-1} onClick={disableSuggestion}>
              <RemoveIcon color="error" />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {suggestion.pesquisa && suggestion.pesquisa.length > 0 && (
        <Grid
          container
          style={{
            margin: 8,
            borderRadius: 16,
            marginTop: 4
          }}
        >
          {pesquisa.map(p => (
            <>
              <Grid
                item
                xs={4}
                style={{ padding: 12, opacity: suggestion.removed ? 0.4 : 1 }}
              >
                <Typography variant="body2">
                  Concorrente: {p.competition?.padStart(4, '0')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ padding: 12, opacity: suggestion.removed ? 0.4 : 1 }}
              >
                <Typography variant="body2">
                  {DateTime.fromISO(p.date).toFormat('dd/MM')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ padding: 12, opacity: suggestion.removed ? 0.4 : 1 }}
              >
                <Grid container style={{ alignItems: 'flex-end' }}>
                  <Grid item>
                    <Typography variant="subtitle2">{p.price}</Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginLeft: 16,
                      justityContent: 'baseline'
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => onSelectPrice(p.price)}
                      aria-label="delete"
                    >
                      <CallMadeIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      )}
    </>
  )
}
