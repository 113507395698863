import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
  List,
  ListItem,
  Divider,
  InputAdornment,
  Card,
  Switch,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core'
import React, { Component, Fragment } from 'react'
import { fetchStockoutProducts, saveStockoutProduct } from './StockoutActions'
import StoreService from '../store/StoreService'
import CheckIcon from '@material-ui/icons/Check'
import FeatureBar from '../layout/FeatureBar'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import Loading from '../common/Loading'
import MainContent from '../layout/MainContent'
import Modal from '@material-ui/core/Modal'
import QuotationIcon from '@material-ui/icons/AttachMoney'
import StockoutProductDetails from './StockoutProductDetails'
import { StockoutService } from './StockoutService'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import {
  Search,
  CheckCircleOutline,
  Store,
  ArrowBackIosOutlined,
  ArrowBackOutlined
} from '@material-ui/icons'

function getSorting(order, orderBy) {
  return order === 'asc'
    ? (a, b) => {
        if (b[orderBy] < a[orderBy]) return 1
        if (b[orderBy] > a[orderBy]) return -1
        return 0
      }
    : (a, b) => {
        if (b[orderBy] < a[orderBy]) return -1
        if (b[orderBy] > a[orderBy]) return 1
        return 0
      }
}
class StockoutDepartmentsStats extends Component {
  state = {
    products: undefined,
    departments: [],
    activeDepartment: undefined,
    filtered: [],
    fetching: false,
    fetchError: false,
    page: 0,
    rowsPerPage: 10,
    showOrdered: false,
    showQuotation: false,
    department: '',
    name: '',
    opened: false,
    selected: undefined,
    order: 'asc',
    orderBy: 'department',
    stores: [],
    showStores: false,
    selectedStores: []
  }

  componentDidMount = () => {
    this.setState({ fetching: true }, () => {
      this.fetchDepartments()
    })
  }

  fetchDepartments = async () => {
    let selectedStores = []
    const storedStores = localStorage.getItem('selectedStores')
    if (storedStores) {
      selectedStores = storedStores.split(';')
    }

    const data = await Promise.all([
      StockoutService.departments(selectedStores),
      StoreService.stores()
    ])

    this.setState({ departments: data[0], stores: data[1], selectedStores })
  }

  handleChange = name => event => {
    const term = event.target.value.toLowerCase()
    this.setState({ [name]: term }, () => this.filterProducts())
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  onSubmit = event => {
    event.preventDefault()
  }

  filterDefaultProducts = products =>
    products
      .filter(p => p.ordered !== true)
      .filter(p => p.quotation !== true)
      .sort(getSorting(this.state.order, this.state.orderBy))

  filterProducts = term => {
    const filtered = this.state.products
      .filter(p => {
        let show = true

        if (
          this.state.name !== '' &&
          p.description.toLowerCase().indexOf(this.state.name) == -1
        ) {
          show = false
        }

        if (!this.state.showOrdered && p.ordered) {
          show = false
        }

        if (!this.state.showQuotation && p.quotation) {
          show = false
        }
        return show
      })
      .sort(getSorting(this.state.order, this.state.orderBy))

    this.setState({ filtered: filtered || [] })
  }

  handleOrderedCheck = event => {
    this.setState(
      state => ({ showOrdered: !state.showOrdered }),
      this.filterProducts
    )
  }

  handleQuotationChecked = event => {
    this.setState(
      state => ({ showQuotation: !state.showQuotation }),
      this.filterProducts
    )
  }

  cleanSearch = () => {
    this.setState({
      name: '',
      department: '',
      filtered: this.filterDefaultProducts(this.state.products),
      showOrdered: false,
      showQuotation: false
    })
  }

  onCheck = key => {
    let product
    this.state.products.map(p => {
      if (p._key === key) {
        product = p
        p.ordered = !p.ordered
      }
      return p
    })

    this.props.saveStockoutProduct(product)
    this.filterProducts()
    this.fetchDepartments()
  }

  handleClose = () => {
    this.setState({ opened: false })
  }

  openDetailsModal = key => {
    this.setState({ opened: true, selected: key })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy }, () => this.filterProducts())
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleQuotation = key => {
    const { products } = this.state
    let product
    products.map(p => {
      if (p._key === key) {
        product = p
        p.quotation = !p.quotation
      }
      return p
    })
    StockoutService.updateProduct(product, { quotation: product.quotation })
    this.filterProducts()
    this.fetchDepartments()
  }

  downloadQuotation = async () => {
    const products = await StockoutService.quotationDownload().catch(e =>
      console.log(e)
    )

    if (!products) {
      return
    }

    const element = document.createElement('a')
    const file = new Blob(products, { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = `ruptura-${new Date().toISOString().substr(0, 10)}.txt`
    element.click()
  }

  selectDepartment = dep => {
    this.setState({ activeDepartment: dep }, () =>
      StockoutService.productsForDepartment(dep.department, this.state.selectedStores)
        .then(response => response.data)
        .then(products =>
          this.setState({
            fetching: false,
            products,
            filtered: this.filterDefaultProducts(products)
          })
        )
        .catch(e => console.log(e))
    )
  }

  downloadCSV = async request => {
    const products = await StockoutService.productsCSV().catch()

    if (!products || products.length == 0) {
      return
    }

    const element = document.createElement('a')
    const file = new Blob(products, { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = `ruptura-${Date.now()}.txt`
    element.click()
  }

  handleSelectStore = store => {
    const { selectedStores } = this.state
    const index = selectedStores.indexOf(store)
    if (index > -1) {
      selectedStores.splice(index, 1)
    } else {
      selectedStores.push(store)
    }
    localStorage.setItem('selectedStores', selectedStores.join(';'))
    this.setState({ selectedStores, activeDepartment: undefined }, this.fetchDepartments)
  }

  render = () => {
    const { classes } = this.props

    const {
      filtered,
      rowsPerPage,
      page,
      order,
      orderBy,
      departments,
      products,
      activeDepartment,
      stores,
      showStores,
      selectedStores
    } = this.state
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, filtered.length - page * rowsPerPage)

    let totalQuotation = 0
    if (products) {
      products.forEach(p => {
        if (p.quotation) {
          totalQuotation++
        }
      })
    }

    return (
      <Fragment>
        <Modal open={this.state.opened} onClose={this.handleClose}>
          <div style={getModalStyle()} className={classes.paper}>
            <StockoutProductDetails
              onClose={this.handleClose}
              product={this.state.selected}
            />
          </div>
        </Modal>
        <Grid
          style={{ right: showStores ? 0 : -250 }}
          container
          direction="column"
          alignItems="flex-start"
          className={classes.storeSidebar}
        >
          <Grid item>
            <Button
              onClick={() => this.setState({ showStores: false })}
              style={{ padding: 16 }}
            >
              <ArrowBackOutlined />
            </Button>
          </Grid>
          <List style={{ width: 250 }}>
            {stores &&
              stores.map(s => {
                const isSelected = selectedStores.indexOf(s.store_code) > -1
                return (
                  <Fragment key={s.store_code}>
                    <ListItem
                      button
                      onClick={() => this.handleSelectStore(s.store_code)}
                    >
                      <ListItemText
                        style={{
                          color: isSelected ? '#1B3A57' : 'rgba(0, 0, 0, 0.26)'
                        }}
                      >
                        {s.store_code} - {s.short_name}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <CheckCircleOutline
                          color={isSelected ? 'secondary' : 'disabled'}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </Fragment>
                )
              })}
          </List>
        </Grid>
        <Grid container direction="row">
          <Grid className={classes.sidebar} item xs={2}>
            <List>
              {departments.length === 0 ? (
                <Loading />
              ) : (
                departments.map(d => (
                  <Fragment key={d.department}>
                    <ListItem onClick={() => this.selectDepartment(d)} button>
                      <Grid container direction="column">
                        <Typography variant="h6">{d.description}</Typography>
                        <Typography variant="body2">
                          {d.amount} produtos
                        </Typography>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))
              )}
            </List>
          </Grid>
          <Grid item xs={10}>
            <FeatureBar
              right={
                <Grid item style={{ marginTop: 32 }}>
                  <Button
                    color={selectedStores.length > 0 ? 'secondary' : 'inherit'}
                    onClick={() => this.setState({ showStores: true })}
                    startIcon={<Store />}
                  >
                    Lojas
                  </Button>
                </Grid>
              }
              title={
                activeDepartment
                  ? `Ruptura - ${activeDepartment.description}`
                  : 'Selecione um departamento'
              }
            />
            {!activeDepartment ? (
              <MainContent>
                <Card
                  elevation={0}
                  style={{ padding: 16, textAlign: 'center' }}
                >
                  <Typography variant="body1">
                    Selecione um departamento para visualizar os produtos em
                    ruptura
                  </Typography>
                </Card>
              </MainContent>
            ) : (
              <Fragment>
                <MainContent container>
                  <Card elevation={0}>
                    <TextField
                      id="search"
                      fullWidth
                      placeholder="Nome do produto..."
                      variant="outlined"
                      onChange={this.handleChange('name')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Card>
                </MainContent>
                <Grid container>
                  <Grid container direction="row" justify="space-between">
                    <Grid container item xs={6}>
                      <Grid item>
                        <FormControlLabel
                          style={{ marginLeft: 24 }}
                          control={
                            <Switch
                              checked={this.state.showOrdered}
                              onChange={this.handleOrderedCheck}
                              color="secondary"
                            />
                          }
                          label="Mostrar pedidos"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          style={{ marginLeft: 24 }}
                          control={
                            <Switch
                              checked={this.state.showQuotation}
                              onChange={this.handleQuotationChecked}
                              color="secondary"
                            />
                          }
                          label="Mostrar cotação"
                        />
                      </Grid>
                    </Grid>
                    <div style={{ marginRight: 32 }}>
                      <Grid container direction="row" justify="flex-end">
                        {/* <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ marginLeft: 24 }}
                    onClick={this.downloadQuotation}
                    className={this.props.classes.loginButton}
                  >
                    Cotação
                  </Button>
                </Grid> */}
                        <Grid item>
                          <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginLeft: 24 }}
                            onClick={this.downloadCSV}
                            className={this.props.classes.loginButton}
                          >
                            Baixar CSV
                          </Button>
                          <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginLeft: 24 }}
                            onClick={this.downloadQuotation}
                          >
                            Baixar cotação
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <div style={{ margin: 32, marginBottom: 1 }}>
                  <Card elevation={0} style={{ padding: 16 }}>
                    <Grid container justify="space-between">
                      <Grid item xs={6}>
                        <Typography variant="caption">Cotação</Typography>
                        <Typography variant="h6">
                          {totalQuotation} produto(s)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </div>
                <MainContent>
                  <Grid spacing={8} container wrap="wrap">
                    {this.state.fetching ? <Loading /> : null}
                    {this.state.filtered && !this.state.fetching ? (
                      <Fragment>
                        <Grid item xs={12}>
                          {/* <div style={{ marginBottom: 16 }} elevation={1}>
                        <form
                          onSubmit={this.onSubmit}
                          noValidate
                          autoComplete="off"
                        >
                          <Button
                            style={{ marginLeft: 24 }}
                            onClick={this.cleanSearch}
                            className={this.props.classes.loginButton}
                          >
                            Limpar
                          </Button>
                        </form>
                      </div> */}
                          <Paper elevation={0}>
                            <Table>
                              <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleRequestSort}
                                rowCount={this.state.filtered.lenght}
                              />
                              {this.state.filtered ? (
                                <TableBody>
                                  {filtered
                                    .slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                    .map((d, i) => (
                                      <TableRow className={classes.row} key={i}>
                                        <TableCell
                                          style={{
                                            opacity: d.ordered ? 0.4 : 1
                                          }}
                                        >
                                          <Typography variant="caption">
                                            {d.code}
                                          </Typography>
                                          <br />
                                          <Typography variant="body1">
                                            {d.description}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            opacity: d.ordered ? 0.4 : 1
                                          }}
                                        >
                                          <Typography variant="body1">
                                            {d.curve}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            opacity: d.ordered ? 0.4 : 1
                                          }}
                                        >
                                          <Typography variant="body1">
                                            {d.inventory}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            opacity: d.ordered ? 0.4 : 1
                                          }}
                                        >
                                          <Typography variant="body1">
                                            {d.stores && d.stores.join('/')}
                                          </Typography>
                                        </TableCell>
                                        <TableCell>
                                          <Tooltip
                                            title="Pedido"
                                            placement="top"
                                          >
                                            <IconButton
                                              className={
                                                d.ordered
                                                  ? classes.checked
                                                  : classes.notChecked
                                              }
                                              onClick={() =>
                                                this.onCheck(d._key)
                                              }
                                            >
                                              <CheckIcon />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip title="Info" placement="top">
                                            <IconButton
                                              className={
                                                d.ordered
                                                  ? classes.checked
                                                  : classes.notChecked
                                              }
                                              className={
                                                d.ordered
                                                  ? classes.checked
                                                  : classes.notChecked
                                              }
                                              onClick={() =>
                                                this.openDetailsModal(d._key)
                                              }
                                            >
                                              <InfoIcon />
                                            </IconButton>
                                          </Tooltip>
                                          <Tooltip
                                            title="Cotação"
                                            placement="top"
                                          >
                                            <IconButton
                                              className={
                                                d.quotation
                                                  ? classes.quotationChecked
                                                  : classes.quotation
                                              }
                                              className={
                                                d.quotation
                                                  ? classes.quotationChecked
                                                  : classes.quotation
                                              }
                                              onClick={() =>
                                                this.handleQuotation(d._key)
                                              }
                                            >
                                              <QuotationIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  {emptyRows > 0 && (
                                    <TableRow
                                      style={{ height: 48 * emptyRows }}
                                    >
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}
                                </TableBody>
                              ) : null}
                              <TableFooter>
                                <TableRow>
                                  <TablePagination
                                    colSpan={8}
                                    labelRowsPerPage={false}
                                    count={this.state.filtered.length}
                                    rowsPerPage={10}
                                    page={page}
                                    onChangeRowsPerPage={
                                      this.handleChangeRowsPerPage
                                    }
                                    onChangePage={this.handleChangePage}
                                  />
                                </TableRow>
                              </TableFooter>
                            </Table>
                          </Paper>
                        </Grid>
                      </Fragment>
                    ) : null}
                  </Grid>
                </MainContent>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

const rows = [
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Produto'
  },
  { id: 'abc', numeric: false, disablePadding: false, label: 'Curva' },
  { id: 'inventory', numeric: false, disablePadding: false, label: 'Estoque' },
  { id: 'stores', numeric: false, disablePadding: false, label: 'Lojas' },
  { id: 'nada', numeric: false, disablePadding: false, label: '' }
]

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy, rowCount } = this.props

    return (
      <TableHead>
        <TableRow>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

function getModalStyle() {
  const top = window.innerHeight / 2 - 300
  const left = window.innerWidth / 2 - 400

  return {
    top: `${top}px`,
    left: `${left}px`
  }
}

const styles = theme => ({
  storeSidebar: {
    position: 'fixed',
    top: 64,
    transition: 'right 150ms',
    backgroundColor: 'white',
    overflow: 'hidden',
    width: 250,
    height: '100vh',
    zIndex: 50
  },
  sidebar: {
    position: 'static',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  },
  urgent: {
    color: 'red'
  },
  important: {
    color: '#FF9800'
  },
  faded: {
    opacity: 0.3
  },
  checked: {
    color: '#43A047'
  },
  notChecked: {
    opacity: 0.4
  },
  quotationChecked: {
    color: '#FF9800'
  },
  quotation: {
    opacity: 0.4
  },
  paper: {
    position: 'absolute',
    width: theme.spacing(100),
    backgroundColor: theme.palette.background.paper
  }
})

const mapDispatchToProps = dispatch => ({
  fetchProducts: bindActionCreators(fetchStockoutProducts, dispatch),
  saveStockoutProduct: bindActionCreators(saveStockoutProduct, dispatch)
})

const styledComponent = withStyles(styles)(StockoutDepartmentsStats)
export default connect(null, mapDispatchToProps)(styledComponent)
