import React, { Component } from 'react'
import {
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  withStyles
} from '@material-ui/core'
import { Menu, ExitToApp } from '@material-ui/icons'
import { store } from '../Store'
import { ToggleDrawer } from '../drawer/reducer'
import version from '../version.json'

class AppToolBar extends Component {
  logout = () => {}

  render = () => {
    const { classes, logout } = this.props
    return (
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => store.dispatch({ type: ToggleDrawer })}
          className={classes.menuButton}
        >
          <Menu />
        </IconButton>
        <Typography
          className={classes.title}
          variant="subtitle1"
          color="inherit"
          noWrap
        >
          Atlas BI - Inteligência em Negócios
        </Typography>
        <div style={{ flex: 1 }} />
        {version.version}
        <IconButton
          className={classes.button}
          variant="contained"
          onClick={logout}
        >
          <ExitToApp />
        </IconButton>
      </Toolbar>
    )
  }
}

const styles = theme => ({
  button: {
    color: 'white'
  },
  title: {
    flex: 1
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  }
})

export default withStyles(styles)(AppToolBar)
