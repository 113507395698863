import CloseIcon from '@material-ui/icons/Close'
import FeatureBar from '../layout/FeatureBar'
import History from '../History'
import MainContent from '../layout/MainContent'
import React, { Component, Fragment } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import Spacer from '../layout/Spacer'
import StoreService from '../store/StoreService'
import UserService from './UserService'
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
  Dialog,
  Drawer,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import { defaultPermissions, userPermissions } from './Permissions'
import { withStyles } from '@material-ui/core/styles'

const roles = [
  {
    name: 'Diretor',
    value: 'director'
  },
  {
    name: 'Comprador',
    value: 'buyer'
  },
  {
    name: 'Gerente',
    value: 'manager'
  },
  {
    name: 'Supervisor',
    value: 'supervisor'
  }
]

class UserEditContainer extends Component {
  state = {
    user: {
      permissions: defaultPermissions,
      stores: [],
      sectors: [],
      networkLimited: false,
      networks: []
    },
    users: [],
    sectors: [],
    stores: [],
    roles: roles,
    permission: false
  }

  componentDidMount = () => {
    this.fetchUser()
    this.fetchUsers()
    this.fetchSectors()
    this.fetchStores()
  }

  fetchStores = () => {
    StoreService.stores().then(stores => this.setState({ stores }))
  }

  fetchSectors = () => {
    UserService.sectors().then(sectors => this.setState({ sectors }))
  }

  fetchUsers = () => {
    UserService.users().then(users => this.setState({ users }))
  }

  fetchUser = () => {
    const params = this.props.match.params

    if (params && params.key == 'add') {
      return
    }

    if (params && params.key) {
      UserService.user(params.key)
        .then(user => {
          if (!user.stores) {
            user.stores = []
          }

          if (!user.sectors) {
            user.sectors = []
          }

          if (!user.permissions) {
            user.permissions = defaultPermissions
          } else {
            user.permissions = Object.assign(
              defaultPermissions,
              user.permissions
            )
          }

          if (user.networks) {
            if (typeof user.networks !== 'object') {
              user.networks = []
            }
            user.networks = user.networks.join(',')
          }

          this.setState({ user })
        })
        .catch()
    }
  }

  updateField = name => event => {
    const { user } = this.state

    if (name === 'networks') {
      let networks = event.target.value
      if (networks.length === 0) {
        user.networkLimited = false
        user.networks = []
      } else {
        user.networkLimited = true
        user.networks = networks.toUpperCase().split(',')
      }
    } else {
      user[name] = event.target.value
    }

    this.setState({ user })
  }

  handleStoreSelection = store => {
    const { user } = this.state
    if (user.stores.indexOf(store) < 0) {
      user.stores.push(store)
    } else {
      user.stores.splice(user.stores.indexOf(store))
    }
    this.setState({ user })
  }

  handleRestrictWifi = () => {
    const { user } = this.state
    user.networkLimited = !user.networkLimited
    this.setState({ user })
  }

  handlePermissionUpdate = permission => {
    const { user } = this.state
    const perm = user.permissions[permission.value]

    user.permissions[permission.value] = !perm

    if (permission.deactivate.length > 0) {
      const deactivate = permission.deactivate[0]
      user.permissions[deactivate] = false
    }

    this.setState({ user })
  }

  handleSectorSelection = sector => {
    const { user } = this.state
    if (sector == null) {
      user.sectors = []
      this.setState({ user })
      return
    }
    if (sector && user.sectors.indexOf(sector.code) < 0) {
      user.sectors.push(sector.code)
      this.setState({ user })
    } else {
      user.sectors.splice(user.sectors.indexOf(sector.code), 1)
      this.setState({ user })
    }
  }

  updateUser = () => {
    const { user } = this.state
    const params = this.props.match.params

    if (!user.name || !user.email || !user.role || !user.openPassword) {
      return this.setState({
        toast: true,
        toastMessage: 'Preencha os campos obrigatórios'
      })
    }

    if (user.stores.length === 0) {
      return this.setState({
        toast: true,
        toastMessage: 'Selecione ao menos uma loja'
      })
    }

    if (!user.sectors) {
      user.sectors = []
    }

    if (params && params.key === 'add') {
      UserService.create(user)
        .then(response => {
          this.setState({
            toast: true,
            toastMessage: response
          })
          this.props.history.push('/usuarios')
        })
        .catch(e =>
          this.setState({
            toast: true,
            toastMessage: e.message
          })
        )
    } else {
      UserService.update(user)
        .then(response => {
          this.setState({
            toast: true,
            toastMessage: response
          })
        })
        .catch(e => {
          this.setState({
            toast: true,
            toastMessage: e.message
          })
        })
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ toast: false })
  }

  copyPermissions = u => {
    const { user } = this.state
    if (u.permissions) {
      user.permissions = u.permissions
      this.setState({ permission: false })
    } else {
      alert('Este usuário não possui permissões que podem ser copiadas')
      this.setState({ user, permission: false })
    }
  }

  render = () => {
    const { user, users, stores, sectors, permission } = this.state
    const { classes } = this.props
    return (
      <Fragment>
        <FeatureBar
          title="Editar Usuário"
          right={
            <Button
              style={{ alignSelf: 'flex-end' }}
              onClick={this.updateUser}
              variant="contained"
              color="secondary"
            >
              <SaveIcon />
              <Spacer />
              Salvar
            </Button>
          }
        />
        {user && (
          <MainContent>
            <Drawer
              open={permission}
              anchor="right"
              onClose={() => this.setState({ permission: false })}
            >
              <Typography
                style={{ margin: 24, maxWidth: 300 }}
                align="center"
                variant="subtitle1"
              >
                Selecione o usuário para copiar as permissões
              </Typography>
              <List style={{ width: 360 }}>
                {users &&
                  users.map(u => (
                    <ListItem key={u.email} onClick={() => this.copyPermissions(u)} button>
                      <ListItemText primary={u.name} secondary={u.email} />
                    </ListItem>
                  ))}
              </List>
            </Drawer>
            <Grid item xs={12}>
              <form className={classes.container} noValidate autoComplete="off">
                <Grid className={classes.group} container>
                  <Grid item>
                    <Typography variant="h6">Dados do Usuário</Typography>
                  </Grid>
                  <Spacer double />
                  <Grid spacing={4} container direction="row">
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="name"
                        variant="outlined"
                        value={user.name || ''}
                        label="Nome *"
                        onChange={this.updateField('name')}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        id="email"
                        variant="outlined"
                        value={user.email || ''}
                        label="Email *"
                        onChange={this.updateField('email')}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="role">Função *</InputLabel>
                        <Select
                          fullWidth
                          id="role"
                          value={user.role || ''}
                          onChange={this.updateField('role')}
                          label="Função *"
                        >
                          {roles.map(r => (
                            <MenuItem key={r.value} value={r.value}>
                              {r.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="openPassword"
                        value={user.openPassword || ''}
                        label="Senha *"
                        onChange={this.updateField('openPassword')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Spacer />
                <Grid className={classes.group} container>
                  <Grid item xs={12}>
                    <Typography variant="h6">Lojas</Typography>
                  </Grid>
                  <Spacer />
                  <Grid container>
                    {stores.map(s => (
                      <Grid key={s.store_code} item xs={2}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                user.stores.indexOf(s.store_code) > -1 || false
                              }
                              onChange={() =>
                                this.handleStoreSelection(s.store_code)
                              }
                              color="secondary"
                              value={s.store_code}
                            />
                          }
                          label={s.store_code}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Spacer />
                <Grid className={classes.group} container>
                  <Grid item xs={12}>
                    <Typography variant="h6">Setores</Typography>
                  </Grid>
                  <Spacer />
                  <Grid container>
                    <Grid key={'allsectors'} item xs={2}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={user.sectors.length === 0 || false}
                            onChange={() => this.handleSectorSelection(null)}
                            color="secondary"
                            value={null}
                          />
                        }
                        label={'Todos os setores'}
                      />
                    </Grid>
                    {sectors &&
                      sectors.map(s => (
                        <Grid key={s.code} item xs={2}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={
                                  user.sectors.indexOf(s.code) > -1 || false
                                }
                                onChange={() => this.handleSectorSelection(s)}
                                color="secondary"
                                value={s.code}
                              />
                            }
                            label={s.description}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Spacer />
                <Grid className={classes.group} direction="column" container>
                  <Grid item>
                    <Typography variant="h6">Restringir acesso</Typography>
                  </Grid>
                  <Spacer />
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={user.networkLimited || false}
                          onChange={() => this.handleRestrictWifi()}
                        />
                      }
                      label={'Restringir acesso somente pelo Wifi'}
                    />
                  </Grid>
                  <Spacer />
                  <Grid item>
                    <TextField
                      variant="outlined"
                      fullWidth={true}
                      id="networks"
                      value={user.networks}
                      label="Endereços MAC dos roteadores permitidos (separados por vírgula)"
                      onChange={this.updateField('networks')}
                    />
                  </Grid>
                </Grid>
                <Spacer />
                <Grid className={classes.group} container>
                  <Grid container>
                    <Grid item>
                      <Typography variant="h6">Permissões</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        style={{ marginLeft: 48 }}
                        color="secondary"
                        onClick={() => this.setState({ permission: true })}
                      >
                        Copiar Permissões
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: 24 }} container>
                    {userPermissions.map(p => (
                      <Grid key={p.value} item xs={12}>
                        <Grid direction="row" container>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={user.permissions[p.value] || false}
                                  onChange={() =>
                                    this.handlePermissionUpdate(p)
                                  }
                                  value={p.value}
                                />
                              }
                              label={
                                <Grid
                                  style={{ marginBottom: 24, marginLeft: 16 }}
                                  item
                                >
                                  <Typography variant="h6">{p.name}</Typography>
                                  <Typography variant="caption">
                                    {p.description}
                                  </Typography>
                                </Grid>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              open={this.state.toast}
              autoHideDuration={3000}
              onClose={this.handleClose}
              message={<span id="message-id">{this.state.toastMessage}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
          </MainContent>
        )}
      </Fragment>
    )
  }
}

const styles = theme => ({
  group: {
    padding: 16
  },
  container: {
    direction: 'row',
    display: 'flex',
    flexWrap: 'wrap'
  }
})

export default withStyles(styles, { withTheme: true })(UserEditContainer)
