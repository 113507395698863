import Api from '../http/ApiService'

class ScheduleService {
  static create(payload) {
    return Api.post('/price-schedules', payload)
      .then(response => response.data)
      .catch(() => null)
  }

  static schedules() {
    return Api.get('/price-schedules')
      .then(response => response.data)
      .catch(() => null)
  }

  static schedule(schedule) {
    return Api.get(`/price-schedules/${schedule}`)
      .then(response => response.data)
      .catch(() => null)
  }

  static updateProduct(product) {
    return Api.post(`/price-schedules/products`, product)
      .then(response => response.data)
      .catch(() => null)
  }

  static download = (schedule, start, end) => {
    return Api.get(`/price-schedules/${schedule}/${start}/${end}/download`)
      .then(response => response.data)
      .catch(() => null)
  }

  static remove = schedule => {
    return Api.delete(`/price-schedules/${schedule}`)
      .then(response => response.data)
      .catch(() => null)
  }
}

export default ScheduleService
