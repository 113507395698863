import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import { Grid, TextField, Card, Button } from '@material-ui/core'
import { authenticate } from '../auth/AuthActions'
import { bindActionCreators } from 'redux'

class LoginContainer extends Component {
  state = {
    email: '',
    password: ''
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  handleSubmit = event => {
    const email = this.state.email
    const password = this.state.password
    if (email && password) {
      try {
        this.props.authenticate({ email, password })
      } catch (error) {
        console.log('login error')
      }
    }
  }

  render = () => (
    <Grid container className={this.props.classes.container}>
      <Card className={this.props.classes.form}>
        <CardContent>
          <form
            onSubmit={e => e.preventDefault()}
            noValidate
            autoComplete="off"
          >
            <Grid
              style={{ flexDirection: 'column', padding: 16 }}
              container
              spacing={24}
            >
              <img
                src="./assets/images/logo_h.png"
                style={{
                  height: 48,
                  width: 133,
                  margin: '0 auto',
                  marginTop: 24,
                  marginBottom: 24
                }}
              />
              <TextField
                id="email"
                type="email"
                label="E-mail"
                value={this.state.email}
                onChange={this.handleChange}
                margin="normal"
              />
              <TextField
                id="password"
                type="password"
                label="Senha"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleSubmit}
                className={this.props.classes.loginButton}
              >
                Logar
              </Button>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  )
}

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto'
  },
  loginButton: {
    marginTop: 32,
    marginLeft: 0
  },
  form: {
    marginTop: 64,
    alignSelf: 'center',
    minWidth: 360
  }
}

const mapDispatchToProps = dispatch => ({
  authenticate: bindActionCreators(authenticate, dispatch)
})

const LoginContainerStyled = withStyles(styles)(LoginContainer)

export default connect(null, mapDispatchToProps)(LoginContainerStyled)
