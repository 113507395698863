import Dropzone from 'react-dropzone'
import FeatureBar from '../layout/FeatureBar'
import Formatter from '../utils/Formatter'
import GoalsService from './GoalsService'
import React, { Component, Fragment } from 'react'
import Spacer from '../layout/Spacer'
import { read, utils } from 'xlsx'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core'
import { DateTime } from 'luxon'
import { getJsDateFromExcel } from '../utils/excel'

class GoalsImportScreen extends Component {
  state = {
    goals: undefined,
    importing: false,
    success: false
  }

  componentDidMount = () => {}

  handleSelectedFile = files => {
    if (!files || files.length === 0) {
      return
    }

    const reader = new FileReader()
    reader.onload = e => {
      const data = new Uint8Array(e.target.result)
      const workbook = read(data, { type: 'array' })

      let goals = utils.sheet_to_json(workbook.Sheets['metas'], {
        blankrows: false,
        skipHeader: false
      })

      goals = goals.map(p => {
        const keys = Object.keys(p)
        let date = p[keys[0]]

        let goalDate
        if (typeof date === 'number') {
          goalDate = DateTime.fromJSDate(getJsDateFromExcel(date)).toUTC()
        } else {
          const parts = date.split('/')

          let originalDay = Number.parseInt(parts[0])
          let originalMonth = Number.parseInt(parts[1])
          let originalYear = parts[2]

          if (originalDay < 10) {
            originalDay = `0${originalDay}`
          }

          if (originalMonth < 10) {
            originalMonth = `0${originalMonth}`
          }

          if (originalYear.length < 4) {
            originalYear = `20${originalYear}`
          }

          const tmpDate = originalDay + '/' + originalMonth + '/' + originalYear
          goalDate = DateTime.fromFormat(tmpDate, 'dd/MM/yyyy')
        }

        let goal = p[keys[2]]
        if (typeof goal === 'string') {
          goal = Number.parseFloat(goal.replace(',', '.'))
        }

        return {
          date: goalDate.toISODate(),
          store: `${p[keys[1]]}`,
          goal
        }
      })

      this.setState({ goals })
    }
    reader.readAsArrayBuffer(files[0])
  }

  handleImportGoals = async () => {
    const { goals } = this.state
    try {
      this.setState({ importing: true })
      await GoalsService.import(goals)
      this.setState({ success: true, importing: false, goals: null })
    } catch (e) {
      this.setState({ importing: false })
    }
  }

  downloadModel = () => {
    const element = document.createElement('a')
    element.href =
      'https://berrytech-ebooks.s3-sa-east-1.amazonaws.com/metas.zip'
    element.download = `metas.zip`
    element.click()
  }

  render () {
    const { goals, importing, success } = this.state
    return (
      <Fragment>
        <Container padding={16}>
          <FeatureBar title='Importar Metas' />
          <Grid container justify='flex-end'>
            <Grid item>
              <Button style={{ marginRight: 36 }} onClick={this.downloadModel}>
                Baixar Modelo
              </Button>
            </Grid>
            {goals && (
              <Grid item>
                <Button
                  variant='contained'
                  onClick={this.handleImportGoals}
                  color='secondary'
                >
                  Importar Metas
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container direction='column'>
            <Spacer double />
            {importing && (
              <Grid style={{ margin: 32 }} container justify='center'>
                <CircularProgress variant='indeterminate' color='secondary' />
              </Grid>
            )}
            {goals ? (
              <Fragment>
                <TableContainer elevation={0} component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Unidade</TableCell>
                        <TableCell>Meta</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {goals.map(p => {
                        return (
                          <TableRow container>
                            <TableCell item>{p.date}</TableCell>
                            <TableCell item>{p.store}</TableCell>
                            <TableCell item>
                              {Formatter.currency(p.goal, 0)}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Fragment>
            ) : (
              <Dropzone onDrop={this.handleSelectedFile}>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject
                }) => {
                  const style = {
                    ...baseStyle,
                    ...(isDragActive ? activeStyle : {}),
                    ...(isDragAccept ? acceptStyle : {}),
                    ...(isDragReject ? rejectStyle : {})
                  }
                  return (
                    <section>
                      <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        <p>
                          Arraste AQUI o arquivo de metas ou clique para
                          selecionar um arquivo
                        </p>
                      </div>
                    </section>
                  )
                }}
              </Dropzone>
            )}
            {success && (
              <Grid style={{ marginTop: 64 }} container justify='center'>
                <Typography variant='h4'>
                  Metas importadas com sucesso
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </Fragment>
    )
  }
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const activeStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#00e676'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

export default GoalsImportScreen
