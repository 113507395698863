import authReducer from './auth/AuthReducer'
import departmentReducer from './department/DepartmentReducer'
import drawerReducer from './drawer/reducer'
import goalsReducer from './goal/GoalsReducer'
import priceReducer from './price/PriceReducer'
import rootSaga, { sagaMiddleware } from './Sagas'
import stockoutReducer from './stockout/StockoutReducer'
import storeReducer from './store/StoreReducer'
import { applyMiddleware, combineReducers, createStore } from 'redux'

const reducer = combineReducers({
  drawer: drawerReducer,
  auth: authReducer,
  department: departmentReducer,
  store: storeReducer,
  goals: goalsReducer,
  stockout: stockoutReducer,
  price: priceReducer
})

const store = createStore(reducer, {}, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)

export { store }
