import CardSection from './CardSection'
import { Paper } from '@material-ui/core'
import React from 'react'
import { TextSecondary } from '../Colors'
import { lightFont } from '../Styles'
import { withStyles } from '@material-ui/core/styles'

const NoResults = ({ classes, message }) =>
  <Paper elevation={0}>
    <CardSection>
      <div className={classes.message}>{message || 'Nenhum resultado encontrado'}</div>
    </CardSection>
  </Paper>

const styles = theme => ({
  message: {
    textAlign: 'center',
    fontFamily: lightFont,
    fontSize: 20,
    color: TextSecondary,
    padding: 16,
  }
})

export default withStyles(styles)(NoResults)