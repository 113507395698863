import { DEPARTMENT_FETCH_SUCCESS } from './DepartmentActions'

const initialState = {
  departments: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEPARTMENT_FETCH_SUCCESS: {
      if (!action.payload) return state
      return { ...state, departments: action.payload }
    }
    default:
      return state
  }
}

export default reducer

export const getDepartments = state => state.department.departments