import React from 'react'
import { ContentBackground } from '../Colors'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: ContentBackground,
    padding: theme.spacing(4)
  }
})

const MainContent = ({ classes, children }) => (
  <main className={classes.content}>{children}</main>
)

export default withStyles(styles)(MainContent)
