import Api from '../http/ApiService'

export class RecoverService {
  static suggestions = () =>
    Api.get('/recover/suggestions')
      .then(response => response.data)
      .catch(e => null)

  static suggestionDetails = sale =>
    Api.get(`/recover/suggestions/${sale}`)
      .then(response => response.data)
      .catch(e => null)

  static markAsDone = sale =>
    Api.post(`/recover/suggestions/${sale}/done`).catch(e => null)

  static products = sale =>
    Api.get(`/recover/suggestions/${sale}/changes`)
      .then(response => response.data)
      .catch(e => null)

  static download = sale =>
    Api.get(`/recover/suggestions/${sale}/products/download`)
      .then(response => response.data)
      .catch(e => null)

  static downloadWithDate = recover =>
    Api.post(`/recover/suggestions/products/download`, recover)
      .then(response => response.data)
      .catch(e => null)
}

export default RecoverService
