import CloudDownload from '@material-ui/icons/CloudDownload'
import Dates from '../utils/date'
import FeatureBar from '../layout/FeatureBar'
import Formatter from '../utils/Formatter'
import hours from '../utils/hours'
import LocalOfferOutlined from '@material-ui/icons/LocalOfferOutlined'
import MainContent from '../layout/MainContent'
import PriceEdit from '../recover_schedule/PriceEdit'
import React, { Component, Fragment } from 'react'
import ScheduleService from './ScheduleService'
import Spacer from '../layout/Spacer'
import {
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  Container
} from '@material-ui/core'

class PriceScheduleProductsContainer extends Component {
  state = {
    startTime: '',
    endTime: '',
    downloadModalOpened: false,
    products: undefined,
    downloading: false
  }

  componentDidMount = () => {
    this.fetchProductsForSchedule()
  }

  fetchProductsForSchedule = () => {
    ScheduleService.schedule(this.props.match.params.schedule).then(
      products => {
        products = products.map(p => {
          p.suggestions = p.suggestions.sort(this.compareStores)
          return p
        })
        this.setState({ products })
      }
    )
  }

  handlePriceChange = product => index => event => {
    const { products } = this.state

    const pIndex = products.findIndex(p => p._id === product._id)
    let editProduct = products[pIndex]

    const suggestion = editProduct.suggestions[index]

    if (!suggestion.originalPrice) {
      suggestion.originalPrice = suggestion.suggestedPrice
    }

    suggestion.updatedPrice = event.target.value

    editProduct.suggestions[index] = suggestion
    products[pIndex] = editProduct

    this.setState({ products })
  }

  handleEdit = product => index => {
    const { products } = this.state

    const pIndex = products.findIndex(p => p._id === product._id)
    let editProduct = products[pIndex]

    const suggestion = editProduct.suggestions[index]

    suggestion.originalPrice = suggestion.suggestedPrice
    suggestion.updatedPrice = suggestion.suggestedPrice
    suggestion.editing = true

    editProduct.suggestions[index] = suggestion
    products[pIndex] = editProduct

    this.setState({ products })
  }

  commitChange = product => index => {
    const { products } = this.state

    const pIndex = products.findIndex(p => p._id === product._id)
    let editProduct = products[pIndex]

    const suggestion = editProduct.suggestions[index]

    suggestion.suggestedPrice = +suggestion.updatedPrice
      .toString()
      .replace(',', '.')
    suggestion.editing = false

    editProduct.suggestions[index] = suggestion
    products[pIndex] = editProduct

    ScheduleService.updateProduct(product)
    this.setState({ products })
  }

  disableSuggestion = product => index => {
    const { products } = this.state

    const pIndex = products.findIndex(p => p._id === product._id)
    let editProduct = products[pIndex]

    const suggestion = editProduct.suggestions[index]

    suggestion.removed = true

    editProduct.suggestions[index] = suggestion
    products[pIndex] = editProduct

    ScheduleService.updateProduct(product)
    this.setState({ products: products })
  }

  enableSuggestion = product => index => {
    const { products } = this.state

    const pIndex = products.findIndex(p => p._id === product._id)
    let editProduct = products[pIndex]

    const suggestion = editProduct.suggestions[index]

    suggestion.removed = false

    editProduct.suggestions[index] = suggestion
    products[pIndex] = editProduct

    ScheduleService.updateProduct(product)
    this.setState({ products })
  }

  handleDisableAll = product => () => {
    const { products } = this.state

    const pIndex = products.findIndex(p => p._id === product._id)
    let editProduct = products[pIndex]

    editProduct.suggestions.map(s => {
      s.removed = true
      return s
    })

    products[pIndex] = editProduct
    ScheduleService.updateProduct(product)
    this.setState({ products })
  }

  handleKeyPress = product => index => event => {
    if (event.defaultPrevented) {
      return
    }

    const key = event.key || event.keyCode

    if (event.charCode === 13) {
      this.commitChange(product)(index)
    }

    if (key === 'Escape' || key === 'Esc' || key === 27) {
      const { products } = this.state

      const pIndex = products.findIndex(p => p._id === product._id)
      let editProduct = products[pIndex]

      const suggestion = editProduct.suggestions[index]

      suggestion.editing = false

      editProduct.suggestions[index] = suggestion
      products[pIndex] = editProduct

      this.setState({ products })
    }
  }

  downloadProducts = () => {
    const { startTime, endTime } = this.state

    if (startTime == '' || endTime == '') {
      return
    }

    this.setState({ downloading: true, downloadModalOpened: false })
    ScheduleService.download(
      this.props.match.params.schedule,
      startTime,
      endTime
    )
      .then(data => {
        this.setState({ downloading: false })
        const name = data.name.replace(' ', '_').toLowerCase()
        const products = data.products

        const element = document.createElement('a')
        const file = new Blob(products, { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = `${name}.txt`
        element.click()
      })
      .catch(() => this.setState({ downloading: false }))
  }

  compareStores(a, b) {
    const bandA = a.store.toUpperCase()
    const bandB = b.store.toUpperCase()

    let comparison = 0
    if (bandA > bandB) {
      comparison = 1
    } else if (bandA < bandB) {
      comparison = -1
    }
    return comparison
  }

  render = () => {
    const { classes } = this.props
    const {
      products,
      downloading,
      startTime,
      endTime,
      downloadModalOpened
    } = this.state

    let total = 0

    if (products) {
      let suggestions = []
      products.forEach(p => p.suggestions.forEach(s => suggestions.push(s)))

      total = total = suggestions
        .filter(p => !p.removed)
        .reduce(
          (a, b) => a + (b.suggestedPrice - b.price) * b.estimatedTotalSales,
          0
        )
    }

    const dates = (products && products[0]) || {}

    return (
      <Container maxWidth="lg">
        <Card
          style={{
            position: 'fixed',
            top: 90,
            left: '70%',
            padding: 16,
            zIndex: 100
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.accent}
                align="center"
                variant="h5"
              >
                {Formatter.currency(total)}
              </Typography>
            </Grid>
            <Grid justify="center" container item xs={12}>
              <Typography align="center" variant="caption">
                Previsão de Recuperação
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <FeatureBar title={'Produtos da agenda'} />
        <Modal
          open={downloadModalOpened}
          onClose={() => this.setState({ downloadModalOpened: false })}
        >
          <div
            style={{
              ...getModalStyle(),
              width: 500,
              height: 250,
              position: 'absolute',
              backgroundColor: '#fff',
              borderRadius: 16
            }}
          >
            <div style={{ padding: 24 }}>
              <Typography variant="subtitle2">
                Selecione os horários:
              </Typography>
              <Spacer double />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    {Dates.format(dates.start || '')}
                  </Typography>
                  <Spacer />
                  <FormControl variant="outlined" style={{ minWidth: 220 }}>
                    <InputLabel htmlFor="start">Início</InputLabel>
                    <Select
                      label="Início"
                      onChange={e =>
                        this.setState({ startTime: e.target.value })
                      }
                      id="start"
                      fullWidth
                      variant="outlined"
                      value={startTime}
                    >
                      {hours.map(h => (
                        <MenuItem key={h} value={h}>
                          {h}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    {Dates.format(dates.end || '')}
                  </Typography>
                  <Spacer />
                  <FormControl variant="outlined" style={{ minWidth: 220 }}>
                    <InputLabel htmlFor="end">Encerramento</InputLabel>
                    <Select
                      label="Encerramento"
                      onChange={e => this.setState({ endTime: e.target.value })}
                      id="end"
                      fullWidth
                      variant="outlined"
                      value={endTime}
                    >
                      {hours.map(h => (
                        <MenuItem key={h} value={h}>
                          {h}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid style={{ padding: '24px 0' }}>
                {downloading ? (
                  <div style={{ height: 52 }}>
                    <LinearProgress color="secondary" />
                  </div>
                ) : (
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        size="large"
                        variant="contained"
                        onClick={this.downloadProducts}
                        fullWidth
                        color="secondary"
                      >
                        Baixar
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </Modal>
        <MainContent>
          <Grid item xs={12}>
            {downloading ? (
              <div style={{ height: 52 }}>
                <LinearProgress color="secondary" />
              </div>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                onClick={() => this.setState({ downloadModalOpened: true })}
                style={{
                  marginBottom: 16,
                  alignSelf: 'center'
                }}
              >
                <CloudDownload
                  style={{
                    marginRight: 8,
                    fontSize: 20,
                    color: '#ffffff'
                  }}
                />
                Baixar Preços
              </Button>
            )}
          </Grid>
          <Card style={{ padding: 16 }} variant="outlined" color="secondary">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={classes.accent}
                  align="center"
                  variant="h3"
                >
                  {Formatter.currency(total)}
                </Typography>
              </Grid>
              <Grid justify="center" container item xs={12}>
                <Typography align="center" variant="body1">
                  Previsão de Recuperação
                </Typography>
              </Grid>
            </Grid>
          </Card>
          <Spacer double />
          {products === undefined ? (
            <Paper>
              <Grid>
                <Typography>Aguarde, carregando produtos...</Typography>
              </Grid>
            </Paper>
          ) : (
            <List>
              {products &&
                products.map((p, index) => {
                  let hasSuggestions = false
                  p.suggestions.forEach(s =>
                    !s.removed ? (hasSuggestions = true) : null
                  )
                  return (
                    <Paper
                      key={p._id}
                      elevation={0}
                      style={{
                        marginBottom: 16,
                        opacity: hasSuggestions ? 1 : 0.4
                      }}
                    >
                      <Fragment>
                        <ListItem style={{ padding: 20, height: 76 }}>
                          <Grid container justify="space-between">
                            <Grid container item xs={10}>
                              <Grid item>
                                <LocalOfferOutlined
                                  style={{ marginRight: 16, marginTop: 4 }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">
                                  {p.description} - {p.brand}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid style={{ alignSelf: 'flex-end' }} item xs={2}>
                              {hasSuggestions && (
                                <Button onClick={this.handleDisableAll(p)}>
                                  Remover Produto
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider />
                        <Grid style={{ padding: 24 }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Unidade</TableCell>
                                <TableCell>Preço</TableCell>
                                <TableCell>Preço Sugerido</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {p.suggestions.map((s, i) => (
                                <PriceEdit
                                  onEnable={this.enableSuggestion(p)}
                                  onDisable={this.disableSuggestion(p)}
                                  onEdit={this.handleEdit(p)}
                                  onCommit={this.commitChange(p)}
                                  onKeyPress={this.handleKeyPress(p)}
                                  onPriceChange={this.handlePriceChange(p)}
                                  key={s.store + i}
                                  index={i}
                                  suggestion={s}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Fragment>
                    </Paper>
                  )
                })}
            </List>
          )}
        </MainContent>
      </Container>
    )
  }
}

function getModalStyle() {
  const top = window.innerHeight / 2 - 300
  const left = window.innerWidth / 2 - 200

  return {
    top: `${top}px`,
    left: `${left}px`
  }
}

const styles = theme => ({
  accent: {
    color: theme.palette.secondary.main
  }
})

export default withStyles(styles)(PriceScheduleProductsContainer)
