import { all } from 'redux-saga/effects'
import createSagaMiddleware from 'redux-saga'
import authSaga from './auth/AuthSaga'
import goalsSaga from './goal/GoalsSaga'
import stockoutSaga from './stockout/StockoutSaga'
import storeSaga from './store/StoreSaga'
import priceSaga from './price/PriceSaga'

export const sagaMiddleware = createSagaMiddleware()

export default function* rootSaga() {
  yield all([
    ...authSaga,
    ...goalsSaga,
    ...stockoutSaga,
    ...storeSaga,
    ...priceSaga,
  ])
}