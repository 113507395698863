export const TextLink = '#4c687f'
export const White = '#FFFFFF'
export const Black = '#000000'
export const Green = '#43A047'

export const Neutral = '#1E88E5'
export const Gold = '#FBC02D'
export const Primary = '#2196F3'
export const Negative = '#666666'
export const Positive = '#43A047'
export const Accent = '#FF5722'
export const Border = '#EDEDED'
export const Ripple = '#EDEDED'
export const InputBorder = '#999999'
export const DarkGrey = '#666666'
export const DrawerColor = '#18202C'

export const TextPrimary = '#1B3A57'
export const TextSecondary = '#5b7d99'
export const TextHint = '#4c687f'

export const NavbarBackground = '#F5F7F9'
export const GoalLineBackground = '#F5F7F9'
export const ChatBackground = '#F5F7F9'
export const ContentBackground ='#F5F7F9'
export const ListAlternateBackgroundColor = '#FAFAFA'
export const CardButtonAreaBackground = '#F5F7F9'

export const GoalLineBad = '#5b7d99'
export const GoalLineGood = '#5b7d99'
export const GoalLineExcelent = '#43A047'

export const BarChartGoal = '#d6d8d8'
