import CloseIcon from '@material-ui/icons/Close'
import FeatureBar from '../layout/FeatureBar'
import History from '../History'
import MainContent from '../layout/MainContent'
import React, { Component, Fragment } from 'react'
import Spacer from '../layout/Spacer'
import UserService from './UserService'
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField
} from '@material-ui/core'
import { Edit, HistoryOutlined } from '@material-ui/icons'

class UsersContainer extends Component {
  state = {
    users: undefined,
    fetching: false,
    toast: false,
    toastMessage: '',
    role: '',
    filtered: [],
    term: ''
  }

  constructor(props) {
    super(props)
    const stored = localStorage.getItem('filter')
    if (stored) {
      const filter = JSON.parse(stored)
      this.state.role = filter.role
      this.state.term = filter.term
    }
  }

  componentDidMount = () => {
    this.fetchUsers()
  }

  fetchUsers = () => {
    UserService.users()
      .then(users => this.setState({ users, filtered: users }, this.filterUser))
      .catch()
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ toast: false })
  }

  handleReset = key => {
    UserService.reset(key)
      .then(() => {
        this.setState({
          toast: true,
          toastMessage: 'Usuário reiniciado com sucesso'
        })
      })
      .catch(() =>
        this.setState({
          toast: true,
          toastMessage: 'Erro ao reiniciar o usuário'
        })
      )
  }

  handleStatusChange = user => event => {
    if (user.active) {
      UserService.deactivate(user._key).then(() => this.fetchUsers())
    } else {
      UserService.activate(user._key).then(() => this.fetchUsers())
    }
  }

  filterUser = () => {
    const { users, role, term } = this.state

    let filtered = users
    if (term !== '') {
      filtered = users.filter(u =>
        u.name.toLowerCase().includes(term.toLowerCase())
      )
    }
    if (role !== '') {
      filtered = filtered.filter(u => u.role.includes(role))
    }
    localStorage.setItem('filter', JSON.stringify({ role, term }))
    this.setState({ filtered })
  }

  handleRoleChange = e => {
    this.setState({ role: e.target.value }, this.filterUser)
  }

  handleNameChange = e => {
    this.setState({ term: e.target.value }, this.filterUser)
  }

  render = () => {
    const { filtered, role, term } = this.state
    return (
      <Fragment>
        <FeatureBar title="Usuários" />
        <MainContent>
          <Grid justify="flex-end" container>
            <Grid item>
              <Button
                onClick={() => this.props.history.push(`/usuarios/add`)}
                variant="contained"
                color="secondary"
              >
                Adicionar Usuário
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <FormControl style={{ width: 200 }}>
                <InputLabel>Função</InputLabel>
                <Select
                  value={role}
                  placeholder="Filtrar por Função"
                  onChange={this.handleRoleChange}
                  inputProps={{
                    name: 'Função',
                    id: 'role'
                  }}
                >
                  <MenuItem value={'director'}>Diretor</MenuItem>
                  <MenuItem value={'buyer'}>Comprador</MenuItem>
                  <MenuItem value={'manager'}>Gerente</MenuItem>
                  <MenuItem value={'supervisor'}>Supervisor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl style={{ width: 200, marginLeft: 32 }}>
                <TextField
                  label="Nome"
                  value={term}
                  onChange={this.handleNameChange}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: 12, marginLeft: 32 }}
                onClick={() =>
                  this.setState({ term: '', role: '' }, this.filterUser)
                }
              >
                Limpar
              </Button>
            </Grid>
          </Grid>
          <Spacer double />
          <Grid container direction="row">
            <Grid item xs={12}>
              <Paper elevation={0}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">Ativo</TableCell>
                      <TableCell align="right">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtered ? (
                      filtered.map(u => (
                        <TableRow key={u._id} hover={true}>
                          <TableCell>
                            <Typography variant="body2">{u.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{u.email}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography variant="body2">
                              <Switch
                                onChange={this.handleStatusChange(u)}
                                checked={u.active}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip placement="top" title="Reiniciar">
                              <IconButton
                                onClick={() => this.handleReset(u._key)}
                              >
                                <HistoryOutlined color="primary" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip placement="top" title="Editar">
                              <IconButton
                                onClick={() =>
                                  this.props.history.push(`/usuarios/${u._key}`)
                                }
                              >
                                <Edit color="primary" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Nenhum usuário cadastrado
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            open={this.state.toast}
            autoHideDuration={3000}
            onClose={this.handleClose}
            message={<span id="message-id">{this.state.toastMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </MainContent>
      </Fragment>
    )
  }
}

export default UsersContainer
